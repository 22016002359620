import React, {Component} from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import ClassRoom from "../../../img/ClassRoom.jpeg";
import { Doughnut, Pie  } from 'react-chartjs-2';
import { FaWallet } from "react-icons/fa";
import { Table} from "react-bootstrap";
import GaugeChart from 'react-gauge-chart'

const data = {
	labels: [
		'Real Money',
		'Cashback Money',
		'Earning Money',
        'Coupon Money',
        'Intelligent Money'
	],
	datasets: [{
		data: [300, 50, 100,30,20],
		backgroundColor: [
		'green',
		'red',
		'black',
        'pink',
        'yellow'
		],
		hoverBackgroundColor: [
		'#FF6384',
		'#36A2EB',
		'#FFCE56'
		]
	}],
  text: 'wallet'
};

class Wallet extends  Component{
    render() {
        return (
            <div id={"main-contain"} style={{ width: "100%",marginTop:2,
                marginBottom:2, marginLeft:0, padding:0,borderRadius:0, boxShadow: "0px 0px 8px black"}}>
                <div style={{float:"right", marginTop:10, marginRight:10}}>
                </div>
                <div style={{clear:"both"}}></div>

                <div id="F4EScholarships">
                    <Row>
                        <Col>
                            <div id="user-personal-detail" style={{ width: 200, height:50, marginLeft: 20, padding:5, boxShadow: "2px 2px 5px black",backgroundColor:"white", borderRadius:5, backgroundImage:`url(${ClassRoom})` }}>
                                <FaWallet style={{float:"left", marginTop:6, height:30,width:30}}/> 
                                <h3 style={{float:"left", marginLeft:10, marginTop:3}}>Your Wallet</h3>
                            </div>
                        </Col>
                        
                    </Row>
                    <Row style={{marginTop:20}}>

                        <Col md={4}>
                            <div id="user-personal-detail" style={{ width: "80%", height:"80%", marginLeft: 50, padding:5, boxShadow: "2px 2px 5px black",backgroundColor:"white", borderRadius:5, backgroundImage:`url(${ClassRoom})` }}>
                                <GaugeChart id="gauge-chart5" style={{height:500, width:540, marginLeft:-125 , marginTop:150}}
								nrOfLevels={420}
								arcsLength={[0.1,0.2, 0.2,0.3, 0.1,0.1]}
								textColor="red"
								colors={['#EA4228', '#F5CD19','#5BE12C','#5BEFFC']}
								percent={.90}
								arcPadding={0.02}
							/>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div id="user-personal-detail" style={{ width: "80%", height:"80%", marginLeft: 20, padding:5, boxShadow: "2px 2px 5px black",backgroundColor:"white", borderRadius:5, backgroundImage:`url(${ClassRoom})` }}>
                                <Pie width={300} height={300}  data={data} options={{
                                    legend: {
                                        display: true
                                      },
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    }}/>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div id="user-personal-detail" style={{ width: "80%", height:"80%", marginRight: 20, padding:5, boxShadow: "2px 2px 5px black",backgroundColor:"white", borderRadius:5, backgroundImage:`url(${ClassRoom})` }}>
                                <Doughnut width={300} height={300}  data={data} options={{
                                    legend: {
                                        display: true
                                      },
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    }}/>
                            </div>
                        </Col>
                        
                    </Row>
                    <Row style={{marginTop:0}}>
                        <Col>
                            <div id="user-personal-detail" style={{ width: "96%", marginLeft: 20, padding:0, boxShadow: "2px 2px 5px black",backgroundColor:"white", borderRadius:5, backgroundImage:`url(${ClassRoom})` }}>
                                <Card>
                                    <Card.Header style={{backgroundColor:"grey"}}>
                                        <h6>Wallet Detail</h6>
                                    </Card.Header>
                                     <Card.Body style={{marginTop:0, marginBottom:0, padding: 0}}>
                                        <Table striped bordered hover size="sm" variant="" style={{margin:0, padding: 0}}>
                                            <tbody>
                                                <tr>
                                                    <td>@Realmoney</td>
                                                    <td>This is your real money</td>
                                                </tr>
                                                <tr>
                                                    <td>@Cashback</td>
                                                    <td>This is cashback money it might be expired</td>
                                                </tr>
                                                <tr>
                                                    <td>@Earnmoney</td>
                                                    <td>This is earn money it might be expired</td>
                                                </tr>
                                                <tr>
                                                    <td>@Earnmoney</td>
                                                    <td>This is earn money it might be expired</td>
                                                </tr>
                                                <tr>
                                                    <td>@Earnmoney</td>
                                                    <td>This is earn money it might be expired</td>
                                                </tr>
                                
                                            </tbody>
                                        </Table>
                                     </Card.Body>
                                     <Card.Footer style={{backgroundColor:"grey"}}>
                                        <h6>**Detail or might change</h6>
                                     </Card.Footer>
                                </Card>
                            </div>
                        </Col>
                        <Col>
                            <div id="user-personal-detail" style={{ width: "96%", marginRight: 20, padding:0, boxShadow: "2px 2px 5px black",backgroundColor:"white", borderRadius:5, backgroundImage:`url(${ClassRoom})` }}>
                                <Card style={{height:255}}>
                                   
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default Wallet;