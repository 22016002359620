import React, {Component} from 'react';
import {Card, Col, Row} from 'react-bootstrap'
import Button from "react-bootstrap/Button";
import {Table} from 'react-bootstrap'

import RTHome from "../../img/home/ReturnHome.png";
import {CENTRAL_CONTENT} from "../constants/Constants";
import {updateCentralContent, updateStdClass} from "../actions/Actions";
import {connect} from "react-redux";
import {F4E_COURSES, USER_INFO} from "../constants/ComponentConst";
import axios from "axios";
import * as ApiUrl from "../../api-url/ApiUrl";

const listOfStreamStd=new Set();

class PageNumIndicator extends  Component{

    constructor(props) {
        super(props);
        this.state = {
            togglingDetail:false,
            isLoading: false,
            signupInfo:{}
        };
    }


    componentDidMount() {
        
        axios.get(ApiUrl.GET_COURSE).then(response=> {
            if (response.status === 200) {
            
                this.setState({ isLoading: false });
            }else { 
               
                throw new Error("Bad response from server"); }
        }).catch((err)=>{
            this.setState({signupInfo: {errorStatus:true, msg:err.response.data.message}});
            setTimeout(() => {
                this.setState({signupInfo: {errorStatus:false, msg:err.response.data.message}});
            }, 4000)
        });

    


    }

    updatePageStdClass=()=>{
        let  centralContent = {
            type:"EDUCATION_STRUCT",
            stream: "Basic Education",
            std: this.props.streamStd,
            page: this.props.pageNum-1,
            pageSize: 8
        }
        console.log("************* PageNumIndicator -> Page number has been clicked: "+this.props.pageNum);
        this.props.updateCentralContent(centralContent);
    }
    

    render() {

        const { isLoading } = this.state;

        if (isLoading) {
            console.log("::::loading..... -->");
            return <div className="App">Loading...</div>;
        }

        console.log(":::::loading done");
        return (
        
            <div style={{ width:'25px', height:'25px', display:"flex", alignItems:"center",alignContent:"center",
                marginTop:2,marginLeft:7, marginRight:3, marginBottom:5, paddingTop:15,paddingLeft:10, 
                boxShadow: "1px 1px 3px black", position:"relative",borderRadius:2, backgroundColor:this.props.isCurrentPage == true ? "red": "Cornsilk"
            }} onClick={()=>this.updatePageStdClass()} >
                <p>{this.props.pageNum}</p>
            </div>

        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data)),
    updateStdClass:data=>dispatch(updateStdClass(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent,
    stdClass: state.deskReducer.updateStdClass
})

export default connect(mapStateToProps,mapDispatchToProps)(PageNumIndicator);