import React, {Component} from 'react';
import {Card, Col, Row} from 'react-bootstrap'
import Button from "react-bootstrap/Button";
import {Table} from 'react-bootstrap'

import RTHome from "../../img/home/ReturnHome.png";
import {CENTRAL_CONTENT} from "../constants/Constants";
import {updateCentralContent, updateStdClass} from "../actions/Actions";
import {connect} from "react-redux";
import {F4E_COURSES, USER_INFO} from "../constants/ComponentConst";
import axios from "axios";
import * as ApiUrl from "../../api-url/ApiUrl";
import EducationCard from './EducationCard';
import EductionStruct from './EductionStruct';

const listOfStreamStd=new Set();
let countPageRendering = 1;

class CardGrid extends  Component{

    constructor(props) {
        super(props);
        this.state = {
            togglingDetail:false,
            isLoading: false,
            signupInfo:{}
        };
    }


    componentDidMount() {
        /*
        axios.get(ApiUrl.GET_COURSE).then(response=> {
            if (response.status === 200) {
            
                this.setState({ isLoading: false });
            }else { 
               
                throw new Error("Bad response from server"); }
        }).catch((err)=>{
            this.setState({signupInfo: {errorStatus:true, msg:err.response.data.message}});
            setTimeout(() => {
                this.setState({signupInfo: {errorStatus:false, msg:err.response.data.message}});
            }, 4000)
        });

        */


    }

    updatePageStdClassxx=(std,eduLevel)=>{
        
        let  stdClass = {
            type:"EDUCATION_STRUCT",
            stream:  eduLevel,
            std: std,
            page: 0,
            pageSize: 8
        }

        this.props.updateCentralContent(stdClass);
        

       
    }
    
    isCourseEmpty=(std)=> {
        let stream = this.props.centralContent.stream;
        let page = 0; 
        let pageSize = this.props.centralContent.pageSize;  
        axios.get(ApiUrl.GET_COURSE, { params: { std:std, page: page, size: pageSize } }).then(response=> {
            if (response.status === 200) {
                let totalPage = response.data.data.totalPages;
                if( response.data.data.content.length == 0){
                    return true;
                }
                return false;
               
            }else { 
                console.log("$$$$$$$Bad response from server");
                throw new Error("Bad response from server"); }
        }).catch((err)=>{
            this.setState({signupInfo: {errorStatus:true, msg:err.response.data.message}});
            setTimeout(() => {
                this.setState({signupInfo: {errorStatus:false, msg:err.response.data.message}});
            }, 4000)
        });
    }

    render() {

        const { isLoading } = this.state;

        if (isLoading) {
            return <div className="App">Loading...</div>;
        }

        return (
            <div style={{ width:'100px', height:'60px', alignContent:"center",
                marginTop:3,marginLeft:6, marginRight:6, marginBottom:8, padding:5, alignItems:"center",
                boxShadow: "2px 2px 8px black", position:"relative",borderRadius:2, borderRadius:10, backgroundColor:"Cornsilk", background: 'url(' + + ')',
            }} 
            onClick={()=>this.updatePageStdClassxx(this.props.cardDetails.std, this.props.cardDetails.eduLevel)}>
                
                <div style={{ display:"flex", alignContent:'center', justifyContent:'center'}}>
                    <div style={{ display:"flex", alignContent:'center',width:'40px', height:'40px',marginLeft:0, marginTop:4,boxShadow: "4px 4px 6px black", borderRadius:20,justifyContent:'center',backgroundColor:"Cornsilk"}}>
                        <h6 style={{ margin:10}}>{this.props.cardDetails.std}</h6>
                    </div>
                </div>
            
            </div>
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data)),
    updateStdClass:data=>dispatch(updateStdClass(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent,
    stdClass: state.deskReducer.updateStdClass,
})

export default connect(mapStateToProps,mapDispatchToProps)(CardGrid);