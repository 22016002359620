import React, {Component} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap';
import ClassRoom from "../../../img/ClassRoom.jpeg";
import { Doughnut  } from 'react-chartjs-2';
import { FaWallet } from "react-icons/fa";
import { Table,Button} from "react-bootstrap";

const data = {
	labels: [
		'Real Money',
		'Cashback',
		'Yellow'
	],
	datasets: [{
		data: [300, 50, 100],
		backgroundColor: [
		'green',
		'red',
		'#FFCE56'
		],
		hoverBackgroundColor: [
		'#FF6384',
		'#36A2EB',
		'#FFCE56'
		]
	}],
  text: 'wallet'
};

class Setting extends  Component{

    applySetting=(user)=>{
        console.log("User::>>"+user);
    }

    render() {
        return (
            <div id={"main-contain"} style={{ height: window.innerHeight-14, width: "100%",marginTop:2,
                marginBottom:2, marginLeft:0, padding:0,borderRadius:0, boxShadow: "0px 0px 8px black"}}>
                <div style={{float:"right", marginTop:10, marginRight:10}}>
                </div>
                <div style={{clear:"both"}}></div>

                <div id="F4EScholarships">
                    <Row>
                        <Col>
                            <div id="user-personal-detail" style={{ width: 150, height:50, marginLeft: 20, padding:5, boxShadow: "2px 2px 5px black",backgroundColor:"white", borderRadius:5, backgroundImage:`url(${ClassRoom})` }}>
                                <FaWallet style={{float:"left", marginTop:6, height:30,width:30}}/> 
                                <h3 style={{float:"left", marginLeft:10, marginTop:3}}>Setting</h3>
                            </div>
                        </Col>
                        
                    </Row>
                    <Row style={{marginTop:20}}>
                        <Col>
                            <div id="user-personal-detail" style={{ width: "97%", height:"100%", marginLeft: 20, padding:0, boxShadow: "2px 2px 5px black",backgroundColor:"white", borderRadius:5, backgroundImage:`url(${ClassRoom})` }}>
                                <Card style={{backgroundImage:`url(${ClassRoom})` }}>
                                    <Card.Header style={{backgroundColor:"yellow"}}>
                                        <h6>Base Setting</h6>
                                    </Card.Header>
                                     <Card.Body style={{marginTop:0, marginBottom:0, padding: 0}}>
                                       <div style={{height:330}}>
                                            <Form>
                                                <Button>
                                                    Whatsapp Number
                                                </Button>
                                            </Form>
                                       </div>
                                     </Card.Body>
                                     <Card.Footer style={{margin:0, padding:0}}>
                                        <Button variant="warning" style={{float:"right", width:"100%"}}> <h6 style={{float:"right"}}>Apply</h6></Button>
                                     </Card.Footer>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop:20}}>
                        <Col>
                            <div id="user-personal-detail" style={{ width: "96%", marginLeft: 20, padding:0, boxShadow: "2px 2px 5px black",backgroundColor:"white", borderRadius:5, backgroundImage:`url(${ClassRoom})` }}>
                                <Card style={{backgroundImage:`url(${ClassRoom})` }}>
                                    <Card.Header style={{backgroundColor:"red"}}>
                                        <h6>Parent Setting</h6>
                                    </Card.Header>
                                     <Card.Body style={{marginTop:0, marginBottom:0, padding: 0}}>
                                       <div style={{height:90}}>

                                       </div>
                                     </Card.Body>
                                     <Card.Footer style={{margin:0, padding:0}}>
                                        <Button variant="danger" style={{float:"right", width:"100%"}}> <h6 style={{float:"right"}}>Apply</h6></Button>
                                     </Card.Footer>
                                </Card>
                            </div>
                        </Col>
                        <Col>
                            <div id="user-personal-detail" style={{ width: "96%", marginRight: 20, padding:0, boxShadow: "2px 2px 5px black",backgroundColor:"white", borderRadius:5, backgroundImage:`url(${ClassRoom})` }}>
                                <Card style={{backgroundImage:`url(${ClassRoom})` }}>
                                    <Card.Header style={{backgroundColor:"green"}}>
                                        <h6>User Setting</h6>
                                    </Card.Header>
                                     <Card.Body style={{marginTop:0, marginBottom:0, padding: 0}}>
                                       <div style={{height:90}}>

                                       </div>
                                     </Card.Body>
                                     <Card.Footer style={{margin:0, padding:0}}>
                                        <Button variant="success" style={{float:"right", width:"100%"}}> <h6 style={{float:"right"}}>Apply</h6></Button>
                                     </Card.Footer>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default Setting;