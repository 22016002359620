import {Component} from 'react';
import Header from './components/header/Header'
import Home from "./components/home/Home";

class MobileApp extends  Component{

    render() {
        return (
            <Home/>
        )
    }
}

export default MobileApp;
