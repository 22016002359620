import React, {Component} from 'react';
import {Card, Col, Row} from 'react-bootstrap'
import Button from "react-bootstrap/Button";
import {Table} from 'react-bootstrap'
import {
    ALL_STD
} from "../constants/ClassLevelConst";
import RTHome from "../../img/home/ReturnHome.png";
import {CENTRAL_CONTENT} from "../constants/Constants";
import {updateCentralContent, updateStdClass} from "../actions/Actions";
import {connect} from "react-redux";
import {F4E_COURSES, USER_INFO} from "../constants/ComponentConst";
import axios from "axios";
import * as ApiUrl from "../../api-url/ApiUrl";
import PageNumIndicator from './PageNumIndicator';
import CardRowTitle from './CardRowTitle';
import { parseJSON } from 'jquery';


let CURRENT_PAGE;
let ALL_STD_DATA;
const MAX_ROW = 5;
const NUM_OF_COLUMN = 4;
let NUM_OF_ROWS = 2;
let NUM_OF_COL_IN_LAST_ROW = 2;
let DATA;
let semaphore = 0;
let globalStd = -1;
let globalPage = -1;
const listOfStreamStd=new Set();
let isAPITriggerReq = true;


class EductionStruct extends  Component{

    constructor(props) {
        console.log("**************** EducationStruct:constructor");
        super(props);
        this.state = {
            togglingDetail:false,
            details: new Array(MAX_ROW*NUM_OF_COLUMN),
            isLoading: true,
            std : 1,
            page : 1,
            signupInfo:{},
            totalPage: null
        };
    }


    async getDataFromServer(){
        let stream = this.props.centralContent.stream;
        let  std =  this.props.centralContent.std; 
        let page = this.props.centralContent.page; 
        let pageSize = this.props.centralContent.pageSize;  
        const res = await axios.get(ApiUrl.GET_COURSE, { params: {std: std, page: page, size: pageSize } });
        return  res;
    }


    componentUpdateIfRequired=()=>{

        console.log(">>>>>>>>>>>>> shouldComponentUpdate :: Education Struct ::");
        let stream = this.props.centralContent.stream;
        let  std =  this.props.centralContent.std; 
        let page = this.props.centralContent.page; 
        let pageSize = this.props.centralContent.pageSize;  
        isAPITriggerReq = this.props.centralContent.isAPITriggerReq;
        
        console.log(">>>>>>>>>>>>> shouldComponentUpdate ::std "+std);
        console.log(">>>>>>>>>>>>> shouldComponentUpdate ::page "+page);
        console.log(">>>>>>>>>>>>> shouldComponentUpdate ::this.state.std "+this.state.std);
        console.log(">>>>>>>>>>>>> shouldComponentUpdate ::this.state.page "+this.state.page);
        if( this.state.std == std && this.state.page == page){
            console.log(">>>>>>>>>>>>> shouldComponentUpdate :: if section");
                return true;
        }
        
        
        axios.get(ApiUrl.GET_COURSE, { params: {std: std, page: page, size: pageSize } }).then(response=> {
            if (response.status === 200) {
            
                let totalPage = response.data.data.totalPages;
                console.log("DATA::"+totalPage);
                console.log(">>>>>>>>>>>>> shouldComponentUpdate ,");
                
                this.props.centralContent.isAPITriggerReq =  false;
                CURRENT_PAGE = response.data.data.pageable.pageNumber;
                ALL_STD_DATA = response.data.data.content;
                if( ALL_STD_DATA.length == 0){
                    return false;
                }
                console.log("******************SIZE::"+ALL_STD_DATA.length);
                DATA = ALL_STD_DATA;
                for(let i = 0; i < ALL_STD_DATA.length; i++){
                    let localData = ALL_STD_DATA[i];
                    listOfStreamStd.add(localData.streamStd);
                }
                NUM_OF_ROWS = Math.ceil(DATA.length/NUM_OF_COLUMN);
                NUM_OF_COL_IN_LAST_ROW = DATA.length-(NUM_OF_ROWS-1)*NUM_OF_COLUMN;
                for(let i = 0; i < MAX_ROW*NUM_OF_COLUMN;i++){ this.state.details[i] = false; }
                this.setState({ totalPage: totalPage, std: std, page: page});
               
                return true;
            }else { 
                console.log("$$$$$$$Bad response from server");
               
                
                return false;
            }
        }).catch((err)=>{
    
            return false;
        });
       
      

        return true;
    }

    componentDidMount() {
        console.log("**************** EducationStruct:ComponentDidMount");
        let stream = this.props.centralContent.stream;
        let  std =  this.props.centralContent.std; 
        let page = this.props.centralContent.page; 
        let pageSize = this.props.centralContent.pageSize;  
        axios.get(ApiUrl.GET_COURSE, { params: {std: std, page: page, size: pageSize } }).then(response=> {
            if (response.status === 200) {
                let totalPage = response.data.data.totalPages;
                console.log("DATA::"+totalPage);
                this.setState({ totalPage: totalPage});
                CURRENT_PAGE = response.data.data.pageable.pageNumber;
                ALL_STD_DATA = response.data.data.content;
                if( ALL_STD_DATA.length == 0){
                    let  centralContent = {
                        type:"CENTRAL_CONTENT",
                        stream: "Basic Education",
                        std: 4,
                        page: 0,
                        pageSize: 8
                    }
            
                    this.props.updateCentralContent(centralContent);
                    return;
                }
                console.log("******************SIZE::"+ALL_STD_DATA.length);
                DATA = ALL_STD_DATA;
                for(let i = 0; i < ALL_STD_DATA.length; i++){
                    let localData = ALL_STD_DATA[i];
                    listOfStreamStd.add(localData.streamStd);
                }
                NUM_OF_ROWS = Math.ceil(DATA.length/NUM_OF_COLUMN);
                NUM_OF_COL_IN_LAST_ROW = DATA.length-(NUM_OF_ROWS-1)*NUM_OF_COLUMN;
                for(let i = 0; i < MAX_ROW*NUM_OF_COLUMN;i++){ this.state.details[i] = false; }
                this.setState({ isLoading: false });
            }else { 
                console.log("$$$$$$$Bad response from server");
                throw new Error("Bad response from server"); }
        }).catch((err)=>{
            this.setState({signupInfo: {errorStatus:true, msg:err.response.data.message}});
            setTimeout(() => {
                this.setState({signupInfo: {errorStatus:false, msg:err.response.data.message}});
            }, 4000)
        });
    }

    getData=()=>{
        let stream = this.props.centralContent.stream;
        let  std =  this.props.centralContent.std; 
        let page = this.props.centralContent.page; 
        let pageSize = this.props.centralContent.pageSize;  
        ALL_STD_DATA = this.props.centralContent.eduData;
        DATA = ALL_STD_DATA;
        for(let i = 0; i < ALL_STD_DATA.length; i++){
            let localData = ALL_STD_DATA[i];
            listOfStreamStd.add(localData.streamStd);
        }
        NUM_OF_ROWS = Math.ceil(DATA.length/NUM_OF_COLUMN);
        NUM_OF_COL_IN_LAST_ROW = DATA.length-(NUM_OF_ROWS-1)*NUM_OF_COLUMN;
    }


    getMediaDetail=(i,j)=>{
        let columnData =[];
        if(DATA[i*NUM_OF_COLUMN+j].platformDetails.length!=0) {
            for (let k = 0; k < DATA[i * NUM_OF_COLUMN + j].platformDetails.length; k++) {
                let col = <Col xs={3}>
                    <Card.Link href={DATA[i * NUM_OF_COLUMN + j].platformDetails[k].courseUrl}>
                        <div style={{marginLeft: 0, marginTop: 10, marginRight: 0}}>
                            <img style={{width: 20, height: 20}}
                                 className="d-block w-100"
                                 src={DATA[i * NUM_OF_COLUMN + j].platformDetails[k].imageUrl}
                            />
                        </div>
                    </Card.Link>
                </Col>;
                columnData.push(col);
            }
        }else{
            let col = <Col xs={3}>
                <div style={{height:30}}></div>
            </Col>;
            columnData.push(col);
        }
        return columnData;
    }

    //Function is tested and verified
    toggleDetailField = (i,j) => {
        console.log("toggleDetailField");
        this.state.details[i*NUM_OF_COLUMN+j] = !this.state.details[i*NUM_OF_COLUMN+j];
        console.log(this.state.details[i*NUM_OF_COLUMN+j]);
        this.setState({ togglingDetail: !this.state.togglingDetail });
    }

     //Function is tested and verified
     read = (i,j, courseid, coursename) => {
        let  centralContent = {
            type:"COURSE_DETAILS",
            stream: this.props.centralContent.stream,
            std: this.props.centralContent.std,
            page: this.props.centralContent.page,
            courseid: courseid,
            coursename: coursename,
            pageSize: 8
        }

        this.props.updateCentralContent(centralContent);
    }

    giveAllRow=()=>{
       this.componentUpdateIfRequired();
        let rows = [];
        for (let i = 0; i < NUM_OF_ROWS-1; i++) {
            let column = []
            for(let j = 0; j < 4; j++){
                column.push(
                    <Col xs={3}>
                    <Card style={{borderWidth:1, borderColor:"black", borderStyle:"dotted"}}>
                        { !this.state.details[i*NUM_OF_COLUMN+j] &&
                        <img style={{display: 'block', width: '100%', height: 150}}
                             className="d-block w-100"
                             src={DATA[i*NUM_OF_COLUMN+j].imageUrl}
                             alt="Image One"
                        />
                        }
                        {
                            this.state.details[i*NUM_OF_COLUMN+j] &&
                            <Card>
                                <Card.Body>
                                    <Table striped bordered hover style={{fontSize:8, margin:0, height:100}}>
                                        <tbody>
                                            <tr>
                                                <td>Duration</td>
                                                <td>{DATA[i*NUM_OF_COLUMN+j].duration+" "+DATA[i*NUM_OF_COLUMN+j].durationUnit}</td>
                                            </tr>
                                            <tr>
                                                <td>Mode</td>
                                                <td>{DATA[i*NUM_OF_COLUMN+j].mode}</td>
                                            </tr>
                                            <tr>
                                                <td>Head</td>
                                                <td>{DATA[i*NUM_OF_COLUMN+j].head != null?DATA[i*NUM_OF_COLUMN+j].head + " ("+DATA[i*NUM_OF_COLUMN+j].headEmail+" )":"- - -" }</td>
                                            </tr>
                                            <tr>
                                                <td>benefit</td>
                                                <td>{DATA[i*NUM_OF_COLUMN+j].benefit}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        }
                        <Card.Body>
                            <Card.Title>{DATA[i*NUM_OF_COLUMN+j].name}</Card.Title>
                            <Card.Text style={{fontSize:11}}>
                                <Row className="justify-content-xm-center">
                                <Col xs={6}>
                                    {
                                        DATA[i*NUM_OF_COLUMN+j].streamStd != null && DATA[i*NUM_OF_COLUMN+j].streamStd != "0" &&
                                        <div style={{marginTop:10, textAlign:"center", backgroundColor:'white', borderRadius:5, border: "1px solid red"}}>
                                            <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COLUMN+j].id}</p>
                                        </div>
                                    }
                                </Col>

                                <Col xs={6}>
                                    {
                                        DATA[i*NUM_OF_COLUMN+j].streamStd != null && DATA[i*NUM_OF_COLUMN+j].streamStd != "0" &&
                                        <div style={{marginTop:10, textAlign:"center", backgroundColor:'white', borderRadius:5, border: "1px solid red"}}>
                                            <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COLUMN+j].streamStd}</p>
                                        </div>
                                    }
                                </Col>
                                </Row>
                            </Card.Text>

                            <div id="media-button">
                                <Row className="justify-content-xm-center">
                                    {
                                        this.getMediaDetail(i,j)
                                    }
                                </Row>
                            </div>
                            <div id="guide-button">
                                <Row className="justify-content-xm-center">
                                    <Col xs={6}>
                                        {
                                            DATA[i*NUM_OF_COLUMN+j].off != null && DATA[i*NUM_OF_COLUMN+j].off != "0" &&
                                            <div style={{marginTop:10, textAlign:"center", backgroundColor:'white', borderRadius:5, border: "1px solid blue"}}>
                                                <p style={{fontSize: 9,margin:0,padding:0}}>{DATA[i * NUM_OF_COLUMN + j].offKeyword + ":" + DATA[i * NUM_OF_COLUMN + j].off + " "+DATA[i * NUM_OF_COLUMN + j].offMode}</p>

                                            </div>
                                        }
                                    </Col>
                                    <Col xs={3}>
                                        {
                                            <div style={{marginTop:10, textAlign:"center", backgroundColor:'skyblue', borderRadius:5, border: "1px solid red"}} onClick={() => this.toggleDetailField(i, j)}>
                                                <p style={{fontSize: 10,margin:0,padding:0}}>Details</p>
                                            </div>
                                        }
                                    </Col>
                                    <Col xs={3}>
                                        {
                                            <div style={{marginTop:10, textAlign:"center", backgroundColor:'skyblue', borderRadius:5, border: "1px solid red"}} onClick={() => this.read(i, j,DATA[i*NUM_OF_COLUMN+j].id, DATA[i*NUM_OF_COLUMN+j].name)}>
                                                <p style={{fontSize: 10,margin:0,padding:0}}>Read</p>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                            <div id="fee-pay-button">
                                <Row className="justify-content-xm-center">
                                    <Col xs={6}>
                                        {
                                            DATA[i*NUM_OF_COLUMN+j].fee != null && DATA[i*NUM_OF_COLUMN+j].fee != "0" &&
                                            <div style={{marginTop:10, textAlign:"center", backgroundColor:'white', borderRadius:5, border: "1px solid green"}} >
                                                <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i * NUM_OF_COLUMN + j].fee + " " + DATA[i * NUM_OF_COLUMN + j].currency}</p>
                                            </div>
                                        }
                                    </Col>
                                    <Col xs={6}>
                                        {
                                            DATA[i*NUM_OF_COLUMN+j].fee != null && DATA[i*NUM_OF_COLUMN+j].fee != "0" &&
                                            <div style={{marginTop:10, textAlign:"center", backgroundColor:'lightyellow', borderRadius:5, border: "1px solid red",}} onClick={() => this.toggleDetailField(i, j)}>
                                                <p style={{fontSize: 9,margin:0,padding:0}}>Register</p>
                                            </div>
                                        }
                                    </Col>

                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                );
            }

            let finalRow = <Row className="justify-content-xm-center" style={{marginBottom:10}}>{column}</Row>;
            rows.push(finalRow);
        }

        //handling the last row
        let lastRowColumn = []
        let i = NUM_OF_ROWS-1;
        console.log("NumOfCol"+NUM_OF_COL_IN_LAST_ROW);
        for(let j = 0; j < NUM_OF_COL_IN_LAST_ROW; j++){
            lastRowColumn.push(
                <Col xs={3}>
                    <Card style={{borderWidth:1, borderColor:"black", borderStyle:"dotted"}}>
                        { !this.state.details[i*NUM_OF_COLUMN+j] &&
                        <img style={{display: 'block', width: "100%", height: 150}}
                             className="d-block w-100"
                             src={DATA[i*NUM_OF_COLUMN+j].imageUrl}
                             alt="Image One"
                        />
                        }
                        {
                            this.state.details[i*NUM_OF_COLUMN+j] &&
                            <Card>
                                <Card.Body>
                                    <Table striped bordered hover style={{fontSize:8, margin:0, height:100}}>
                                        <tbody>
                                            <tr>
                                                <td>Duration</td>
                                                <td>{DATA[i*NUM_OF_COLUMN+j].duration+" "+DATA[i*NUM_OF_COLUMN+j].durationUnit}</td>
                                            </tr>
                                            <tr>
                                                <td>Mode</td>
                                                <td>{DATA[i*NUM_OF_COLUMN+j].mode}</td>
                                            </tr>
                                            <tr>
                                                <td>Head</td>
                                                <td>{DATA[i*NUM_OF_COLUMN+j].head != null?DATA[i*NUM_OF_COLUMN+j].head + " ("+DATA[i*NUM_OF_COLUMN+j].headEmail+" )":"- - -" }</td>
                                            </tr>
                                            <tr>
                                                <td>benefit</td>
                                                <td>{DATA[i*NUM_OF_COLUMN+j].benefit}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        }
                        <Card.Body>
                            <Card.Title>{DATA[i*NUM_OF_COLUMN+j].name}</Card.Title>
                            <Card.Text style={{fontSize:11}}>
                                <Row className="justify-content-xm-center">
                                <Col xs={6}>
                                    {
                                        DATA[i*NUM_OF_COLUMN+j].streamStd != null && DATA[i*NUM_OF_COLUMN+j].streamStd != "0" &&
                                        <div style={{marginTop:10, textAlign:"center", backgroundColor:'white', borderRadius:5, border: "1px solid red"}}>
                                            <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COLUMN+j].id}</p>
                                        </div>
                                    }
                                </Col>

                                <Col xs={6}>
                                    {
                                        DATA[i*NUM_OF_COLUMN+j].streamStd != null && DATA[i*NUM_OF_COLUMN+j].streamStd != "0" &&
                                        <div style={{marginTop:10, textAlign:"center", backgroundColor:'white', borderRadius:5, border: "1px solid red"}}>
                                            <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COLUMN+j].streamStd}</p>
                                        </div>
                                    }
                                </Col>
                                </Row>
                            </Card.Text>

                            <div id="media-button">
                                <Row className="justify-content-xm-center">
                                    {
                                        this.getMediaDetail(i,j)
                                    }
                                </Row>
                            </div>
                            <div id="guide-button">
                                <Row className="justify-content-xm-center">
                                    <Col xs={6}>
                                        {
                                            DATA[i*NUM_OF_COLUMN+j].off != null && DATA[i*NUM_OF_COLUMN+j].off != "0" &&
                                            <div style={{marginTop:10, textAlign:"center", backgroundColor:'white', borderRadius:5, border: "1px solid blue"}}>
                                                <p style={{fontSize: 9,margin:0,padding:0}}>{DATA[i * NUM_OF_COLUMN + j].offKeyword + ":" + DATA[i * NUM_OF_COLUMN + j].off + " "+DATA[i * NUM_OF_COLUMN + j].offMode}</p>

                                            </div>
                                        }
                                    </Col>
                                    <Col xs={3}>
                                        {
                                            <div style={{marginTop:10, textAlign:"center", backgroundColor:'skyblue', borderRadius:5, border: "1px solid red"}} onClick={() => this.toggleDetailField(i, j)}>
                                                <p style={{fontSize: 10,margin:0,padding:0}}>Details</p>
                                            </div>
                                        }
                                    </Col>
                                    <Col xs={3}>
                                        {
                                            <div style={{marginTop:10, textAlign:"center", backgroundColor:'skyblue', borderRadius:5, border: "1px solid red"}} onClick={() => this.read(i, j,DATA[i*NUM_OF_COLUMN+j].id,DATA[i*NUM_OF_COLUMN+j].name)}>
                                                <p style={{fontSize: 10,margin:0,padding:0}}>Read</p>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                            <div id="fee-pay-button">
                                <Row className="justify-content-xm-center">
                                    <Col xs={6}>
                                        {
                                            DATA[i*NUM_OF_COLUMN+j].fee != null && DATA[i*NUM_OF_COLUMN+j].fee != "0" &&
                                            <div style={{marginTop:10, textAlign:"center", backgroundColor:'white', borderRadius:5, border: "1px solid green"}} >
                                                <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i * NUM_OF_COLUMN + j].fee + " " + DATA[i * NUM_OF_COLUMN + j].currency}</p>
                                            </div>
                                        }
                                    </Col>
                                    <Col xs={6}>
                                        {
                                            DATA[i*NUM_OF_COLUMN+j].fee != null && DATA[i*NUM_OF_COLUMN+j].fee != "0" &&
                                            <div style={{marginTop:10, textAlign:"center", backgroundColor:'lightyellow', borderRadius:5, border: "1px solid red",}} onClick={() => this.toggleDetailField(i, j)}>
                                                <p style={{fontSize: 9,margin:0,padding:0}}>Register</p>
                                            </div>
                                        }
                                    </Col>

                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            );
        }

        let lastRow = <Row className="justify-content-xm-center" style={{marginBottom:10, width:'100%'}}>
            {lastRowColumn}
        </Row>;
        rows.push(lastRow);
        return rows;
    }

    getNumOfPage=()=>{
        let pages =[];
        for(let i = 1; i < this.state.totalPage+1; i++){
            pages.push(<PageNumIndicator isCurrentPage={ i == (CURRENT_PAGE+1) ? true : false} streamStd={this.props.centralContent.std} pageNum={i}/>);
        }
        return pages;
    }

    render() {

        const { isLoading } = this.state;


        if (isLoading) {
            console.log("::::loading..... -->");
            return <div className="App">Loading...</div>;
        }else{
            //this.componentDidUpdatexx();
        }

        return (
            
            <div style={{ width:'100%', alignContent:"center",
                marginTop:3,marginLeft:2, marginBottom:2, padding:5, alignItems:"center",
                boxShadow: "2px 2px 8px black", position:"relative",borderRadius:2
            }} >
                 { console.log(">>>>>>>>>>>>>>>>>>>>Consolexxxxx")}
                <div style={{ display:'flex', alignItems:"center",alignContent:"center", marginTop:3, marginBottom:2, padding:2, borderRadius:2 }}>
                    <div style={{ display:"flex", alignContent:'center', justifyContent:'center'}}>
                        <CardRowTitle title={this.props.centralContent.stream} />
                        <CardRowTitle title={this.props.centralContent.std} />
                    </div>
                </div>

               
                 <div style={{ width:'100%', alignContent:"center",
                 alignItems:"center",padding:15, position:"relative",borderRadius:2
                }} >
                    { this.giveAllRow()}
                </div>
            
                <div style={{ display:'flex', alignContent:'center', justifyContent:'center'}}>
                    {this.props.listOfCard}
                   <div style={{ display:'flex', alignItems:"center",alignContent:"center", marginTop:10, marginBottom:2, padding:2, borderRadius:2 }}>
                        { this.getNumOfPage() }
                    </div>
                </div>
                
            </div>
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent,
    stdClass: state.deskReducer.stdClass
})

export default connect(mapStateToProps,mapDispatchToProps)(EductionStruct);