import React, {Component} from 'react';
import {Card, Col, Row} from 'react-bootstrap'
import Button from "react-bootstrap/Button";
import {Table} from 'react-bootstrap'

import RTHome from "../../img/home/ReturnHome.png";
import {CENTRAL_CONTENT} from "../constants/Constants";
import {updateCentralContent} from "../actions/Actions";
import {connect} from "react-redux";
import {F4E_COURSES, USER_INFO} from "../constants/ComponentConst";
import axios from "axios";
import * as ApiUrl from "../../api-url/ApiUrl";

const listOfStreamStd=new Set();

class CardRowTitle extends  Component{

    constructor(props) {
        super(props);
        this.state = {
            togglingDetail:false,
            isLoading: false,
            signupInfo:{}
        };
    }


    componentDidMount() {
        /*
        axios.get(ApiUrl.GET_COURSE).then(response=> {
            if (response.status === 200) {
            
                this.setState({ isLoading: false });
            }else { 
               
                throw new Error("Bad response from server"); }
        }).catch((err)=>{
            this.setState({signupInfo: {errorStatus:true, msg:err.response.data.message}});
            setTimeout(() => {
                this.setState({signupInfo: {errorStatus:false, msg:err.response.data.message}});
            }, 4000)
        });

        */


    }


    render() {

        const { isLoading } = this.state;

        if (isLoading) {
            console.log("::::loading..... -->");
            return <div className="App">Loading...</div>;
        }

        console.log(":::::loading done");
        return (
            <div style={{  height:'25px', alignContent:"center",
                marginTop:5,marginLeft:2, marginRight:2, marginBottom:15, padding:3, alignItems:"center",display:"flex", alignContent:'center', justifyContent:'center',
                boxShadow: "2px 2px 8px black", position:"relative",borderRadius:2, borderRadius:15, backgroundColor:this.props.backgroundColor
            }} >
                <h6 style={{marginTop:8,padding:5, color: this.props.titleColor}}>{this.props.title}</h6>
            </div>
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent,
    patel:console.log(state)
})

export default connect(mapStateToProps,mapDispatchToProps)(CardRowTitle);