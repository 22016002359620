import {BUY_BOOK, CENTRAL_CONTENT, LOGIN_INFO,STD_CLASS} from "../constants/Constants";

const initialState = {
    books: 1,
    loginInfo:{
        loginStatus:false,
        userEmail:null
    },
    centralContent: {
        type:"CENTRAL_CONTENT",
        std: 4
    }
};

function deskReducer(state = initialState, action) {
    switch (action.type){
        case LOGIN_INFO:
            return {...state, loginInfo: action.loginInfo }
        case BUY_BOOK:
            return {...state, books: state.books+action.bookCount }
        case CENTRAL_CONTENT:
            return {...state, centralContent: action.centralContent }
        default: return state;
    }
};

export default deskReducer;