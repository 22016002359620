import React, {Component} from 'react';
import {Card, Col, Row} from 'react-bootstrap'
import Button from "react-bootstrap/Button";
import {Table} from 'react-bootstrap'

import RTHome from "../../img/home/ReturnHome.png";
import {CENTRAL_CONTENT} from "../constants/Constants";
import {updateCentralContent} from "../actions/Actions";
import {connect} from "react-redux";
import {F4E_COURSES, USER_INFO} from "../constants/ComponentConst";
import axios from "axios";
import * as ApiUrl from "../../api-url/ApiUrl";
import CardGrid from './CardGrid';
import EducationCardRow from './EducationCardRow';

let diffStd=[];
const listOfStreamStd=new Set();

class EductionCard extends  Component{

    constructor(props) {
        super(props);
        this.state = {
            togglingDetail:false,
            isLoading: true,
            signupInfo:{}
        };
    }


    componentDidMount() {
        axios.get(ApiUrl.GET_EDUCATION_LEVEL_STD_MAPPING).then(response=> {
            if (response.status === 200) {
                diffStd = response.data.data;
                this.setState({ isLoading: false });
            }else { 
               
                throw new Error("Bad response from server"); }
        }).catch((err)=>{
            this.setState({signupInfo: {errorStatus:true, msg:err.response.data.message}});
            setTimeout(() => {
                this.setState({signupInfo: {errorStatus:false, msg:err.response.data.message}});
            }, 4000)
        });
    }

    getCardDetailFromDB=()=>{
        axios.get(ApiUrl.GET_EDUCATION_LEVEL_STD_MAPPING).then(response=> {
            if (response.status === 200) {
                diffStd = response.data.data;
            }else { 
               
                throw new Error("Bad response from server"); }
        }).catch((err)=>{
            setTimeout(() => {
                this.setState({signupInfo: {errorStatus:false, msg:err.response.data.message}});
            }, 4000)
        });
    }

    getAllCard=()=>{
        //this.getCardDetailFromDB();
        let rows = [];
        for(let i = 0; i < diffStd.length; i++){
            let cards = [];

            for(let j = 0; j < diffStd.at(i).streamStdDetailsList.length ; j++){
                console.log(">>>>>>>>>:::"+diffStd.at(i).educationLevelDetails.edu_level);
                cards.push(<CardGrid cardDetails = {{ std:diffStd.at(i).streamStdDetailsList[j].streamStd, eduLevel: diffStd.at(i).educationLevelDetails.edu_level} } />)
            }
            
            let row = <EducationCardRow backgroundColor={diffStd.at(i).educationLevelDetails.background_color} title={diffStd.at(i).educationLevelDetails.edu_level} listOfCard={cards} ></EducationCardRow>
            rows.push(row);
        }

        return rows;


    }


    render() {

        const { isLoading } = this.state;

        if (isLoading) {
            console.log("::::loading..... -->");
            return <div className="App">Loading...</div>;
        }

        console.log(":::::loading done");
        return (
            <div>
            {
                this.getAllCard()
            }
            </div>
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent
})

export default connect(mapStateToProps,mapDispatchToProps)(EductionCard);