import React, {Component} from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import ClassRoom from "../../../img/ClassRoom.jpeg";

class Certificate extends  Component{
    render() {
        return (
            <div id={"main-contain"} style={{ height: window.innerHeight-14, width: "100%",marginTop:2,
                marginBottom:2, marginLeft:0, padding:0,borderRadius:0, boxShadow: "0px 0px 8px black"}}>
                <div style={{float:"right", marginTop:10, marginRight:10}}>
                </div>
                <div style={{clear:"both"}}></div>

                <div id="F4EScholarships">
                    <Row>
                        <Col>
                            <div id="user-personal-detail" style={{ width: 250, marginLeft: 20, padding:5, boxShadow: "2px 2px 5px black",backgroundColor:"white", borderRadius:5, backgroundImage:`url(${ClassRoom})` }}>
                            <Row>
                                    <Col>
                                        <p>hi how are </p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        
                    </Row>
                    <Row style={{marginTop:20}}>
                        <Col>
                            <div id="user-personal-detail" style={{ width: 250, marginLeft: 20, padding:5, boxShadow: "2px 2px 5px black",backgroundColor:"white", borderRadius:5, backgroundImage:`url(${ClassRoom})` }}>
                            <Row>
                                    <Col>
                                        <p>hi how are </p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
           
        )
    }
}

export default Certificate;