import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import {Fight4EducObj} from '../../constants/Fight4EduObjective';
import axios from "axios";
import * as ApiUrl from "../../api-url/ApiUrl";
import {FcCloseUpMode} from "react-icons/fc";


class CentralImage extends  Component{

    constructor(props) {
        super(props);
       
    }

    render() {

    

        return (
            <div style={{margin:0, padding:0,height: window.innerHeight*0.6, background: `url("https://ik.imagekit.io/fight4edu/common/objective/sunindark.gif")`,backgroundRepeat: "no-repeat", backgroundSize: "100%" }}>
                <div style={{ width:400, right:50, marginLeft:20 }}>
                    {/* First Element is Logo */}
                    <div style={{alignContent:"center",color:'yellow'}}>
                        <FcCloseUpMode style={{ height:30,width:30}}/>
                        <p style={{fontSize: 14,marginBottom:3,padding:0}}>Welcome</p>
                    </div>
                    <div style={{textAlign:"justify",fontSize: 10,fontStyle:"italic" , color:'#ffffcc', marginTop:0, padding:3,borderRadius:5, border:'0px dotted black'}}>
                
                    </div>  
                </div>
            </div>
        )
    }
}

export default CentralImage;

