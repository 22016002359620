import React, {Component} from 'react';
import {connect} from 'react-redux';
import Footer from "../footer/Footer";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";

import  "../../css/HomeStyle.css";

import CentralContent from "../CentralContent";
import EductionStruct from "../EductionStruct";

import Help from "../login-user-pages/Help";
import Certificate from "../login-user-pages/Certificate";
import F4EObjective from "../../components/F4EObjective";
import Setting from "../login-user-pages/Setting";
import {
    ADMISSION_NOTICE,CENTRAL_CONTENT,CLASSES_TIME_TABLE,
    EXCELLENT_PERFORMER, F4E_SCHOLARSHIPS, GENIUS_OF_MONTH,
    RESULT_AWARD, F4E_COURSES, LOGIN_INFO, USER_INFO,EXAM_AND_TEST,
    CERTIFICATE,HELP,SETTING,WALLET,TIME_TABLE, 
    SEARCH,DETAIL,GRID_MAP, ABOUT_F4E,
    CONTACT,SUCCESS,SCHOLARSHIP_FORM,SCHOLARSHIPS,EDUCATION_STRUCT,COURSE_DETAILS
} from "../../constants/ComponentConst";
import {updateCentralContent} from "../../actions/Actions";
import {instanceOf} from "prop-types";
import {withCookies, Cookies} from "react-cookie";
import ExamAndTests from '../login-user-pages/ExamAndTests';
import Wallet from '../login-user-pages/Wallet';
import TimeAndTable from '../login-user-pages/TimeAndTable';
import SearchPage from '../login-user-pages/SearchPage';
import Detail from "../login-user-pages/Detail";
import UserHome from "../login-user-pages/UserHome";
import Contact from '../Contanct';
import Success from '../Success';
import ScholarshipForm from '../ScholarshipForm';
import EducationCard from '../EducationCard';
import Details from '../Details';



class Home extends  Component{

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        const { cookies } = props;
       
    }

    componentDidMount=()=>{
        const { cookies } = this.props;
        let  centralContent = {
            type:"CENTRAL_CONTENT",
            stream: "Basic Education",
            std: 4
        }
        let  centralContentUser = {
            type:"USER_INFO",
            stream: "Basic Education",
            std: 4
        }
        this.props.updateCentralContent(( cookies.get('f4e_auth') != null ) ? centralContentUser: centralContent);
    }

    scrollUp=()=>{
        window.scroll(0,150);
        return true;
    }

    render() {
        console.log(this.props);
        return (
            <div>
                <Header/>
                <div style={{width:'100%', margin: 0, padding:0}}>
                    <div id="central-container-panel" style={{margin:0, padding: 0, width:'100%'}}>
                        {console.log("*****************this.props.centralContent.type:"+this.props.centralContent.type)}
                        { this.props.centralContent.type == ABOUT_F4E && <F4EObjective/>}
                        { this.props.centralContent.type == HELP && <Help/> } 
                        { this.props.centralContent.type == CONTACT && <Contact/>}
                        { this.props.centralContent.type == SUCCESS && <Success/>}
                        { this.props.centralContent.type == SCHOLARSHIP_FORM && <ScholarshipForm/>}

                        { this.props.centralContent.type == DETAIL && <Detail/>}
                        { this.props.centralContent.type == SEARCH && <SearchPage/>}
                        { this.props.centralContent.type == TIME_TABLE && <TimeAndTable/>}
                        { this.props.centralContent.type == WALLET && <Wallet></Wallet>}
                        { this.props.centralContent.type == SETTING && <Setting/> } 
                        { this.props.centralContent.type == CERTIFICATE && <Certificate/> } 
                        { this.props.centralContent.type == EXAM_AND_TEST && <ExamAndTests/> } 
                        
                        { this.props.centralContent.type === USER_INFO && <UserHome/> }

                        { this.props.centralContent.type === CENTRAL_CONTENT && <CentralContent/>}
                        { this.props.centralContent.type === EDUCATION_STRUCT && <EductionStruct/>}
                        { this.props.centralContent.type === COURSE_DETAILS && <Details/>}
                    </div>
                </div>
                <div id={"main-contain"} style={{clear:"both"}}>
                </div>
                {
                    
                    //<EducationCard/>
                
                }
                <div style={{float:"left", width:'100%'}}>
                    <Footer/>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data))
})

const mapStateToProps=state=>({
      centralContent: state.deskReducer.centralContent,
      //loginInfo: state.deskReducer.loginInfo,
      //stdClass: state.deskReducer.stdClass
})

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(Home));