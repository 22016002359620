import React, {Component} from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import ClassRoom from "../../../img/ClassRoom.jpeg";
import { Doughnut  } from 'react-chartjs-2';
import { FaWallet } from "react-icons/fa";
import { FcAssistant }  from "react-icons/fc";
import {BsChatSquareQuote } from "react-icons/bs";
import { Table,Button} from "react-bootstrap";

const data = {
	labels: [
		'Real Money',
		'Cashback',
		'Yellow'
	],
	datasets: [{
		data: [300, 50, 100],
		backgroundColor: [
		'green',
		'red',
		'#FFCE56'
		],
		hoverBackgroundColor: [
		'#FF6384',
		'#36A2EB',
		'#FFCE56'
		]
	}],
  text: 'wallet'
};

class Help extends  Component{

    applySetting=(user)=>{
        console.log("User::>>"+user);
    }

    render() {
        return (
            <div id={"main-contain"} style={{ height: window.innerHeight-14, width: "100%",marginTop:2,
                marginBottom:2, marginLeft:0, padding:0,borderRadius:0, boxShadow: "0px 0px 8px black"}}>
                <div style={{float:"right", marginTop:10, marginRight:10}}>
                </div>
                <div style={{clear:"both"}}></div>

                <div id="F4EScholarships">
                    <Row>
                        <Col>
                            <div id="user-personal-detail" style={{ width: 200, height:50, marginLeft: 20, padding:5, boxShadow: "2px 2px 5px black",backgroundColor:"white", borderRadius:5, backgroundImage:`url(${ClassRoom})` }}>
                                <FcAssistant style={{float:"left", marginTop:6, height:30,width:30}}/> 
                                <h3 style={{float:"left", marginLeft:10, marginTop:3}}>Help Desk</h3>
                            </div>
                        </Col>
                        
                    </Row>
                    <Row style={{marginTop:20}}>
                        <Col>
                            <div id="user-personal-detail" style={{ width: "97%", marginLeft: 20, padding:0, boxShadow: "2px 2px 5px black",backgroundColor:"white", borderRadius:5, backgroundImage:`url(${ClassRoom})` }}>
                                <Card style={{backgroundImage:`url(${ClassRoom})` }}>
                
                                     <Card.Body style={{marginTop:0, marginBottom:0, padding: 0}}>
                                       <div style={{ marginBottom:30}}>
                                            <Row style={{marginTop:20}}>
                                                <Col>
                                                    <div id="user-personal-detail" style={{ width: "96%", marginLeft: 20, padding:0, boxShadow: "2px 2px 5px black",backgroundColor:"white", borderRadius:5, backgroundImage:`url(${ClassRoom})` }}>
                                                        <Card style={{backgroundImage:`url(${ClassRoom})` }}>
                        
                                                            <Card.Body style={{marginTop:0, marginBottom:0, padding: 0}}>
                                                                <div style={{height:120}}>
                                                                    <Table striped bordered hover size="md" variant="dark" style={{margin:0, padding: 0}}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>email</td>
                                                                                <td>help@fight4edu.com</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>phone</td>
                                                                                <td>9044448083</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>office</td>
                                                                                <td>office@fight4edu.com</td>
                                                                            </tr>
                                                            
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </Card.Body>
                                                            
                                                        </Card>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <Row style={{marginTop:0, marginRight:10}}>
                                                        <Col>
                                                            <div id="user-personal-detail" style={{ width: "96%", marginLeft: 20, padding:0, boxShadow: "2px 2px 5px black",backgroundColor:"white", borderRadius:5, backgroundImage:`url(${ClassRoom})` }}>
                                                                <Card style={{backgroundImage:`url(${ClassRoom})` }}>
                                                                    <Card.Header style={{}}>
                                                                        <BsChatSquareQuote style={{float:"left", marginTop:6, height:30,width:30}}/>
                                                                        <h4 style={{float:"left", marginLeft:10, marginTop:3}}>Online Chat</h4>
                                                                    </Card.Header>
                                                                    <Card.Body style={{marginTop:0, marginBottom:0, padding: 0}}>
                                                                        <div style={{height:420}}>
                                                                            
                                                                            
                                                                        </div>
                                                                    </Card.Body>
                                                                    <Card.Footer style={{margin:0, padding:0}}>
                                                                        <div className="form-group">
                                                                            
                                                                            <textarea
                                                                            className="form-control"
                                                                            id="exampleFormControlTextarea1"
                                                                            rows="4"
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                        <Button style={{float:"right", width:"100%"}}>Send</Button>
                                                                        </div>
                                                                    </Card.Footer>
                                                                </Card>
                                                            </div>
                                                        </Col>
                                                        
                                                    </Row>
                                                </Col>
                                            </Row>
                                            
                                       </div>
                                     </Card.Body>
                                   
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    
                </div>
            </div>
        )
    }
}

export default Help;