import React, {Component} from 'react';
import Button from 'react-bootstrap/Button';
import {connect} from 'react-redux';
import NoticeBoard from "../../../img/NoticeWW.png";
import ClassRoom from "../../../img/ClassRoom.jpeg";
import {updateBook, updateCentralContent} from "../../actions/Actions";
import {
    ADMISSION_NOTICE,
    CLASSES_TIME_TABLE,
    EXCELLENT_PERFORMER, F4E_COURSES, F4E_SCHOLARSHIPS,
    GENIUS_OF_MONTH,
    RESULT_AWARD
} from "../../constants/ComponentConst";
import RTHome from "../../../img/home/ReturnHome.png";
import ProfilePhoto from "../../../img/home/Profile-ICon.png";
import {CENTRAL_CONTENT, LOGIN_INFO} from "../../constants/Constants";
import {instanceOf} from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import {USER_INFO} from "../../../mob/constants/ComponentConst";
import {Card, Col, Row} from 'react-bootstrap';
import {Table} from 'react-bootstrap';
import { FaCertificate } from "react-icons/fa";
import {SiMinds} from "react-icons/si";
import {FcSettings,FcRating,FcAssistant,FcOvertime,FcBinoculars,
    FcPieChart,FcNews,FcViewDetails,FcMindMap,FcCdLogo,FcHome,
    FcIdea,FcDocument,FcPhone} from "react-icons/fc";
import { FaWallet } from "react-icons/fa";

class Sidebar extends  Component{

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        const { cookies } = props;
        this.state = {
            centralContainerHeightChanged : false,
            showUserInfoButton:cookies.get('f4e_auth') != null ? true: false,
        };

        this.props.updateCentralContent(( cookies.get('f4e_auth') != null ) ? USER_INFO: CENTRAL_CONTENT);

    }

    getHeightOfCentralContainer=()=>{
        return document.getElementById("central-container-panel").getBoundingClientRect().height
    }

    changeThePage=()=>{
        console.log("::>>>>"+this.props.centralContent);
        if(this.props.centralContent == USER_INFO){
            this.props.updateCentralContent(CENTRAL_CONTENT);
        }else{
            this.props.updateCentralContent(USER_INFO);
        }
    }

    FcMindMap

    getLoginUserSpecificFeature=()=>{
        let items =[];
        let wowList =[{ "keyword":"DETAIL","buttonName":"Details","icon":<FcPieChart style={{ height:30,width:30}}/> },
        { "keyword":"GRID_MAP","buttonName":"Score","icon":<FcMindMap style={{ height:30,width:30}}/> },
        { "keyword":"SETTING","buttonName":"Setting","icon":<FcSettings style={{ height:30,width:30}}/> },
        { "keyword":"HELP","buttonName":"Help","icon":<FcAssistant style={{ height:30,width:30}}/> },
        { "keyword":"WALLET","buttonName":"Wallet","icon":<FaWallet style={{ height:30,width:30}}/> }];

        //onClick={()=>this.props.updateCentralContent(wowList[i].keyword)}
        for(let i = 0; i < wowList.length; i++ ){
            console.log("***"+wowList[i]);
            let button = <div style={{marginLeft:10, float:"left", marginRight:10}}  >
                <div style={{marginTop:5, alignContent:"center", float:"center"}}>
                    {wowList[i].icon}
                    <p style={{fontSize: 10,marginBottom:3,padding:0}}>{wowList[i].buttonName}</p>
                </div>
            </div>;
            items.push(button);
        }
                
        let firstHalfItems =[];
        let secondHalfItems =[];
        for(let i = 0; i < wowList.length; i++){
            if( i%2 == 0)
                firstHalfItems.push(items[i]);
            else
                secondHalfItems.push(items[i]);
        }        
        let combineRow = 
                        <div>
                            <div >
                                {firstHalfItems}
                            </div>
                            <div>
                                {secondHalfItems}
                            </div>
                        </div>
        return combineRow;
    }

    getAllSlideBarButton=()=>{
        let items =[];
        let wowList =[{ "keyword":"ABOUT_F4E","buttonName":"F4E","icon":<FcCdLogo style={{ height:30,width:30}}/> },
        { "keyword":"SUCCESS","buttonName":"Succes","icon":<FcIdea style={{ height:30,width:30}}/> },
        { "keyword":"CONTACT","buttonName":"Contact","icon":<FcPhone style={{ height:30,width:30}}/> },
        { "keyword":"SCHOLARSHIP_FORM","buttonName":"Scholarship","icon":<FcDocument style={{ height:30,width:30}}/> },
        { "keyword":"CENTRAL_CONTENT","buttonName":"Home","icon":<FcHome style={{ height:30,width:30}}/> },
    ];
    //onClick={()=>this.props.updateCentralContent({ type: wowList[i].keyword, std:4})} 
        for(let i = 0; i < wowList.length; i++ ){
            console.log("***"+wowList[i]);
            let button = <div style={{marginLeft:10, float:"left", marginRight:10}} >
                <div style={{marginTop:5, alignContent:"center", float:"center"}}>
                    {wowList[i].icon}
                    <p style={{fontSize: 10,marginBottom:3,padding:0}}>{wowList[i].buttonName}</p>
                </div>
            </div>;
            items.push(button);
        }
                
        let firstHalfItems =[];
        let secondHalfItems =[];
        for(let i = 0; i < wowList.length; i++){
            //if( i%2 == 0)
            firstHalfItems.push(items[i]);
            //else
            secondHalfItems.push(items[i]);
        }        
        let combineRow = 
                        <div>
                           
                            <div>
                                {secondHalfItems}
                            </div>
                        </div>
        return combineRow;
    }

    render() {
        console.log(this.props);
        return (

            <div>
                <div style={{ width:'100%', height:this.getHeightOfCentralContainer, alignContent:"center",
                    marginTop:0, marginRight:3, right:150, marginBottom:2, padding:5, alignItems:"center",
                }} >
                    {
                        (this.props.loginInfo.loginStatus  || this.state.showUserInfoButton )&&
                        <div style={{backgroundColor:"white",float:'left', marginRight:5, borderRadius:5, backgroundImage:`url(${ClassRoom})` }}>
                            { this.getLoginUserSpecificFeature() }
                        </div>  
                    }
                    <div style={{backgroundColor:"white", float:'left', borderRadius:5, backgroundImage:`url(${ClassRoom})` }}>
                        {this.getAllSlideBarButton()}
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data))
})

const mapStateToProps=state=>({
    loginInfo:state.deskReducer.loginInfo,
    centralContent:state.deskReducer.centralContent
})

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(Sidebar));
