import React, {Component} from "react";
import {connect} from "react-redux";
import Header from "../header/Header";
import {
    ADMISSION_NOTICE,
    CENTRAL_CONTENT,
    CLASSES_TIME_TABLE, EXCELLENT_PERFORMER, F4E_COURSES,
    F4E_SCHOLARSHIPS, GENIUS_OF_MONTH, RESULT_AWARD, LOGIN_INFO,USER_INFO,
    ABOUT_F4E,HELP,CONTACT,SUCCESS,SCHOLARSHIP_FORM
} from "../../constants/ComponentConst"
import F4EObjective from "../F4EObjective"
import Help from "../Help";
import CentralContent from "../CentralContent";
import Contact from "../Contact";
import Success from "../Success";
import Navbar from "../navbar/Navbar";
import {updateCentralContent} from "../../../desk/actions/Actions";
import Footer from "../../components/footer/Footer";
import EductionStruct from "../EductionStruct";
import ScholarshipForm from "../ScholarshipForm";
import UserHome from "../UserHome";
import {instanceOf} from "prop-types";
import {withCookies, Cookies} from "react-cookie";

class Home extends  Component{

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        const { cookies } = props;
        this.props.updateCentralContent(CENTRAL_CONTENT);
    }

    render() {
        return (
            <div>
                <Header/>
                <Navbar style={{zIndex:10000}}></Navbar>
                <div style={{float:"left"}}>
                    { ( this.props.centralContent === USER_INFO ) && <UserHome/>}
                    { this.props.centralContent === LOGIN_INFO && <UserHome/>}
                    { this.props.centralContent == ABOUT_F4E && <F4EObjective/>}
                    { this.props.centralContent == HELP && <Help/> } 
                    { this.props.centralContent == CONTACT && <Contact/>}
                    { this.props.centralContent == SUCCESS && <Success/>}
                    { this.props.centralContent === CENTRAL_CONTENT && <CentralContent/>}
                    { this.props.centralContent == SCHOLARSHIP_FORM && <ScholarshipForm/>}
                </div>
                <div style={{float:"left"}}>
                    <Footer/>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent,
    patel:console.log(state)
})

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(Home));