import React, {Component} from 'react';
import ImageScroller from "./ImageScroller";
import F4EObjective from "./F4EObjective";
import CentralImage from './CentralImage';


class CentralContent extends  Component{
    render() {
        return (
            <div style={{ position: 'relative'}}>

                <div id={"main-contain-center"} style={{ position:'absolute', zIndex:1000,  width: '60%', height: '100px' ,marginTop: window.innerHeight*0.35, marginBottom:2, marginLeft: '20%', padding:0,borderRadius:3, boxShadow: "0px 0px 6px red", backgroundColor:"white"}}>
                    <div style={{position:"relative", height:"100%"}}>
                        <div style={{width:"33%",float:"left", height:"100%"}}>
                            <div style={{ marginLeft: '40%', marginTop:"15px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-camera-reels-fill" viewBox="0 0 16 16">
                                <path d="M6 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                                <path d="M9 6a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
                                <path d="M9 6h.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 7.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 16H2a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2z"/>
                            </svg>
                            </div>
                            <div style={{ marginLeft: '35%', marginTop:"5px"}}>
                                Live Classes
                            </div>
                        </div>
                        <div style={{width:"2px", height:"80%", marginTop: '10px',  float:'left', boxShadow: "0px 0px 6px red"}}></div>
                        <div style={{width:"33%", float:'left', height:"100%"}}>

                            <div style={{ marginLeft: '40%', marginTop:"15px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-person-fill-check" viewBox="0 0 16 16">
                                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                                <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4"/>
                                </svg>
                            </div>
                            <div style={{ marginLeft: '35%', marginTop:"5px"}}>
                                Doubt Resolution
                            </div>
                        </div>
                        <div style={{width:"2px", height:"80%", marginTop: '10px',  float:'left', boxShadow: "0px 0px 6px red"}}></div>
                        <div style={{width:"33%",float:'right', height:"100%"}}>
                            <div style={{ marginLeft: '40%', marginTop:"15px"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-book-half" viewBox="0 0 16 16">
                            <path d="M8.5 2.687c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
                            </svg>
                            </div>
                            <div style={{ marginLeft: '35%', marginTop:"5px"}}>
                                F4E-Papers
                            </div>


                        
                        </div>
                    </div>
                </div>

                <div id={"main-contain-center"} style={{position:'relative', width: '100%', height: window.innerHeight*0.4,marginTop:2,marginBottom:2, marginLeft:0, padding:0,borderRadius:3, boxShadow: "0px 0px 6px black"}}>
                    <div style={{ }}>
                    

                    </div>
                </div>

                <div id={"main-contain-center"} style={{position:'relative', width: '100%', height: window.innerHeight*0.6,marginTop:2,marginBottom:2, marginLeft:0, padding:0,borderRadius:3, boxShadow: "0px 0px 6px black"}}>
                    <div>
                    
                    </div>
                    <div>

                    </div>
                </div>

               

                <div id={"main-contain-center"} style={{position:'relative', width: '100%', height: window.innerHeight*0.2,marginTop:2,marginBottom:2, marginLeft:0, padding:0,borderRadius:3, boxShadow: "0px 0px 6px black"}}>
                    <div>
                    
                    </div>
                    <div>

                    </div>
                </div>

                
            </div>
        )
    }
}

export default CentralContent;