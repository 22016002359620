export const CLASSES_TIME_TABLE = "CLASSES_TIME_TABLE";
export const ADMISSION_NOTICE = "ADMISSION_NOTICE";
export const RESULT_AWARD = "RESULT_AWARD";
export const GENIUS_OF_MONTH = "GENIUS_OF_MONTH";
export const CENTRAL_CONTENT = "CENTRAL_CONTENT";
export const EXCELLENT_PERFORMER = "EXCELLENT_PERFORMER";
export const F4E_SCHOLARSHIPS = "F4E_SCHOLARSHIPS";
export const F4E_COURSES = "F4E_COURSES";
export const LOGIN_INFO = "LOGIN_INFO";
export const USER_INFO = "USER_INFO";

export const ABOUT_F4E = "ABOUT_F4E";
export const HELP = "HELP";
export const CONTACT = "CONTACT";
export const SUCCESS = "SUCCESS";
export const SCHOLARSHIP_FORM = "SCHOLARSHIP_FORM";

