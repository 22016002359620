import React, {Component} from 'react';
import {updateCentralContent} from "../../actions/Actions";
import {connect} from "react-redux";
import {CENTRAL_CONTENT} from "../../constants/Constants";
import {Card, Col, FormControl, InputGroup, Row, Table} from "react-bootstrap";
import {Dropdown} from "react-bootstrap";
import ReactSpeedometer from "react-d3-speedometer";
import GaugeChart from 'react-gauge-chart'


import Chart from 'chart.js/auto';

class UserHome extends  Component{
    chartRef = React.createRef();
	mySChart = React.createRef();
	mySubject = React.createRef();

	constructor(props){
		super(props);
		this.state = {
            studyMeter: .001,
			rankMeter:.001
        };
	}

	tick=()=> {
		this.setState({
		  studyMeter: Math.random().toFixed(2),
		  rankMeter: Math.random().toFixed(2)
		});
	  }

	componentDidMount() {
		const ctx = this.chartRef.current.getContext("2d");
		const ctxS = this.mySChart.current.getContext("2d");
		const ctxSubject = this.mySubject.current.getContext("2d");

		this.interval = setInterval(() => this.tick(), 5000);
		
		
		new Chart(ctx, {
			type: "line",
			data: {
				labels: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
				datasets: [{ 
					data: [86,114,106,106,107,111,133],
					label: "Total",
					borderColor: "#3e95cd",
					backgroundColor: "#7bb6dd",
					fill: false,
				}, { 
					data: [70,90],
					label: "Accepted",
					borderColor: "#3cba9f",
					backgroundColor: "#71d1bd",
					fill: false,
				}, { 
					data: [10,21,60,44,17,21,17],
					label: "Pending",
					borderColor: "#ffa500",
					backgroundColor:"#ffc04d",
					fill: false,
				}, { 
					data: [6,30,2,2,70,0,16],
					label: "Rejected",
					borderColor: "#c45850",
					backgroundColor:"#d78f89",
					fill: true
				}
				]
			},
			options: {
				aspectRatio:2,
				responsive: true,
				plugins: {
				  legend: {
					position: 'top',
				  },
				  title: {
					display: true,
					text: 'Absolute Score'
				  }
				}
			  },
		});


		new Chart(ctxS, {
			type: "bar",
			data: {
				labels: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
				datasets: [{ 
					data: [86,114,106,106,107,111,133],
					label: "Total",
					borderColor: "#3e95cd",
					backgroundColor: "#7bb6dd",
					fill: false,
				}, { 
					data: [70,90],
					label: "Accepted",
					borderColor: "#3cba9f",
					backgroundColor: "#71d1bd",
					fill: false,
				}, { 
					data: [10,21,60,44,17,21,17],
					label: "Pending",
					borderColor: "#ffa500",
					backgroundColor:"#ffc04d",
					fill: false,
				}, { 
					data: [6,3,2,2,7,0,16],
					label: "Rejected",
					borderColor: "#c45850",
					backgroundColor:"#d78f89",
					fill: false,
				}
				]
			},
			options: {
				aspectRatio:2,
				responsive: true,
				plugins: {
				  legend: {
					position: 'top',
				  },
				  title: {
					display: true,
					text: 'All India Percetile'
				  }
				}
			  },
		});

	
		

		new Chart(ctxSubject, {
			type: "bar",
			data: {
				labels: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
				datasets: [{ 
					data: [86,114,106,106,107,111,133],
					label: "Total",
					borderColor: "#3e95cd",
					backgroundColor: "#7bb6dd",
					fill: false,
				}, { 
					data: [70,90],
					label: "Accepted",
					borderColor: "#3cba9f",
					backgroundColor: "#71d1bd",
					fill: false,
				}, { 
					data: [10,21,60,44,17,21,17],
					label: "Pending",
					borderColor: "#ffa500",
					backgroundColor:"#ffc04d",
					fill: false,
				}, { 
					data: [6,3,2,2,7,0,16],
					label: "Rejected",
					borderColor: "#c45850",
					backgroundColor:"#d78f89",
					fill: false,
				}
				]
			},
			options: {
				aspectRatio:2,
				responsive: true,
				plugins: {
				  legend: {
					position: 'top',
				  },
				  title: {
					display: true,
					text: 'Batch Percetile'
				  }
				}
			  },
		});


		
	}



    render() {
        return (
            <div id={"main-contain"} style={{ height: window.innerHeight-14, width: '100%',marginTop:2,
                marginBottom:2, marginLeft:0, padding:0,borderRadius:0, boxShadow: "0px 0px 8px black"}}>
				 <div style={{float:"right", marginTop:10, marginRight:10}}>
                </div>
                <div style={{clear:"both"}}></div>

                <div id="USER_HOME">
                    <div id="UserName" style={{marginLeft:50, marginTop:50}}>
						
						<Row className="justify-content-md-center" style={{marginLeft:-50}}>
						<Col md xs lg="6">
						<div style={{ alignItems:"center", alignContent:"center"}}>
							<GaugeChart id="gauge-chart5" style={{height:450, width:600, marginLeft:-10 , marginTop:50}}
								nrOfLevels={420}
								arcsLength={[0.3, 0.5, 0.2]}
								textColor="red"
								colors={['#EA4228', '#F5CD19','#5BE12C']}
								percent={this.state.studyMeter}
								arcPadding={0.02}
							/>
									
								</div> 
								{
									/*
								<div style={{height:200}}>
									<canvas 
									id="mySChart"
									ref={this.mySChart}
									/>
								</div> 
								*/
								}
							</Col>
							
							<Col md xs lg="6">
								<div>
									<canvas
									id="myChart"
									ref={this.chartRef}
									/>
								</div> 
							</Col>
					
						</Row>

						<Row className="justify-content-md-center"  style={{marginTop:-150,marginLeft:-50}}>
							<Col md xs lg="6" style={{ alignItems:"center"}}>
								<div style={{height:200}}>
									<canvas 
									id="mySChart"
									ref={this.mySChart}
									/>
								</div> 

							</Col>
						
							<Col md xs lg="6">
								<div>
									<canvas 
									id="mySubject"
									ref={this.mySubject}
									/>
								</div> 
							</Col>
						</Row>
						
	
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent,
    loginInfo: state.deskReducer.loginInfo,
    patel:console.log(state)
})

export default connect(mapStateToProps,mapDispatchToProps)(UserHome);