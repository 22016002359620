import { rgb } from 'd3-color';
import React, {Component} from 'react';
import {Card, Col, Row, Table,ProgressBar, Button } from 'react-bootstrap';
import ClassRoom from "../../../img/ClassRoom.jpeg";

const DATA =[
                {
                    "imageUrl":"https://res.cloudinary.com/stealthman22/image/upload/v1586308023/new-portfolio/hero/two-cargo-ships-sailing-near-city-2144905.jpg",
                    "courseRegDateKey":"Reg Date",
                    "courseRegDate":"04-09-21",
                    "courseIDKey":"Course ID",
                    "courseID":"TUE889822",
                    "CourseNameKey":"course Name",
                    "courseName":"Physics",
                    "batchNameKey":"batchName",
                    "batchName":"A",
                    "batchStartDateKey":"Start Date",
                    "batchStartDate":"10-09-21",
                    "batchStatus":"RUNNING",
                    "batchProgress":60
                },
                {
                    "imageUrl":"https://res.cloudinary.com/stealthman22/image/upload/v1586308023/new-portfolio/hero/two-cargo-ships-sailing-near-city-2144905.jpg",
                    "courseRegDateKey":"Reg Date",
                    "courseRegDate":"04-09-21",
                    "courseIDKey":"Course ID",
                    "courseID":"TUE889822",
                    "CourseNameKey":"course Name",
                    "courseName":"Physics",
                    "batchNameKey":"batchName",
                    "batchName":"A",
                    "batchStartDateKey":"Start Date",
                    "batchStartDate":"10-09-21",
                    "batchStatus":"RUNNING",
                    "batchProgress":60
                },
                {
                    "imageUrl":"https://res.cloudinary.com/stealthman22/image/upload/v1586308023/new-portfolio/hero/two-cargo-ships-sailing-near-city-2144905.jpg",
                    "courseRegDateKey":"Reg Date",
                    "courseRegDate":"04-09-21",
                    "courseIDKey":"Course ID",
                    "courseID":"TUE889822",
                    "CourseNameKey":"course Name",
                    "courseName":"Physics",
                    "batchNameKey":"batchName",
                    "batchName":"A",
                    "batchStartDateKey":"Start Date",
                    "batchStartDate":"10-09-21",
                    "batchStatus":"RUNNING",
                    "batchProgress":60
                },
                {
                    "imageUrl":"https://res.cloudinary.com/stealthman22/image/upload/v1586308023/new-portfolio/hero/two-cargo-ships-sailing-near-city-2144905.jpg",
                    "courseRegDateKey":"Reg Date",
                    "courseRegDate":"04-09-21",
                    "courseIDKey":"Course ID",
                    "courseID":"TUE889822",
                    "CourseNameKey":"course Name",
                    "courseName":"Physics",
                    "batchNameKey":"batchName",
                    "batchName":"A",
                    "batchStartDateKey":"Start Date",
                    "batchStartDate":"10-09-21",
                    "batchStatus":"RUNNING",
                    "batchProgress":60
                },
                {
                    "imageUrl":"https://res.cloudinary.com/stealthman22/image/upload/v1586308023/new-portfolio/hero/two-cargo-ships-sailing-near-city-2144905.jpg",
                    "courseRegDateKey":"Reg Date",
                    "courseRegDate":"04-09-21",
                    "courseIDKey":"Course ID",
                    "courseID":"TUE889822",
                    "CourseNameKey":"course Name",
                    "courseName":"Physics",
                    "batchNameKey":"batchName",
                    "batchName":"A",
                    "batchStartDateKey":"Start Date",
                    "batchStartDate":"10-09-21",
                    "batchStatus":"RUNNING",
                    "batchProgress":60
                },
                {
                    "imageUrl":"https://res.cloudinary.com/stealthman22/image/upload/v1586308023/new-portfolio/hero/two-cargo-ships-sailing-near-city-2144905.jpg",
                    "courseRegDateKey":"Reg Date",
                    "courseRegDate":"04-09-21",
                    "courseIDKey":"Course ID",
                    "courseID":"TUE889822",
                    "CourseNameKey":"course Name",
                    "courseName":"Physics",
                    "batchNameKey":"batchName",
                    "batchName":"A",
                    "batchStartDateKey":"Start Date",
                    "batchStartDate":"10-09-21",
                    "batchStatus":"RUNNING",
                    "batchProgress":60
                },
                {
                    "imageUrl":"https://res.cloudinary.com/stealthman22/image/upload/v1586308023/new-portfolio/hero/two-cargo-ships-sailing-near-city-2144905.jpg",
                    "courseRegDateKey":"Reg Date",
                    "courseRegDate":"04-09-21",
                    "courseIDKey":"Course ID",
                    "courseID":"TUE889822",
                    "CourseNameKey":"course Name",
                    "courseName":"Physics",
                    "batchNameKey":"batchName",
                    "batchName":"A",
                    "batchStartDateKey":"Start Date",
                    "batchStartDate":"10-09-21",
                    "batchStatus":"RUNNING",
                    "batchProgress":60
                }
            ]


const NUM_OF_COL = 3;
const BOOT_COL_SPACE = 12/NUM_OF_COL;
const numOfColInLastRow = DATA.length%NUM_OF_COL;
const numOfRow = (DATA.length-numOfColInLastRow)/NUM_OF_COL;

const HEIGHT_CALLER = 0;
const STRIPEED_DETAIL_COLR = "MistyRose"
class Detail extends  Component{

    getCoursesDetails=()=>{
        let table = [];

        for(let i = 0; i < 4; i++){
            let tbrow = <tr>
                            <td>04-09-21</td>
                            <td>TUE889822</td>
                            <td>Physics</td>
                            <td>Batch-A</td>
                            <td>10-09-21</td>
                            <td>Running</td>
                            <td><ProgressBar now={60*Math.random()} /></td>
                        </tr>;
            table.push(tbrow);
        }


        return table;
    }

    getHeight =()=>{
       
    }

    coursesDetailInGrid=()=>{
    
        console.log("numOfColInLastRow: "+numOfColInLastRow);
        console.log("numOfRow: "+numOfRow);

        let rows = [];
        for (let i = 0; i < numOfRow; i++) {
            let column = []
            for(let j = 0; j < NUM_OF_COL; j++){
                column.push(
                    <Col xs={BOOT_COL_SPACE}>
                    <Card style={{borderWidth:1, borderColor:"black", borderStyle:"dotted"}}>
                        <img style={{display: 'block', width: window.innerWidth, height: 150}}
                                className="d-block w-100"
                                src={DATA[i*NUM_OF_COL+j].imageUrl}
                                alt="Image One"
                        />
                        <Card.Body>
                            <div id="Course-Batch">
                                <div style={{padding:5, marginTop: 2, textAlign:"center", backgroundColor: STRIPEED_DETAIL_COLR, borderRadius:5}}>           
                                    <Row className="justify-content-xm-center">
                                        <Col md={6}>
                                            <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                                <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].courseIDKey}</p>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                            <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].courseID}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{padding:5, marginTop: 2, textAlign:"center", backgroundColor: STRIPEED_DETAIL_COLR, borderRadius:5}}>           
                                    <Row className="justify-content-xm-center">
                                        <Col md={6}>
                                            <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                                <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].CourseNameKey}</p>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                            <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].courseName}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{padding:5, marginTop: 2, textAlign:"center", backgroundColor: STRIPEED_DETAIL_COLR, borderRadius:5}}>           
                                    <Row className="justify-content-xm-center">
                                        <Col md={6}>
                                            <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                                <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].courseRegDateKey}</p>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                            <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].courseRegDate}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{padding:5, marginTop: 2, textAlign:"center", backgroundColor: STRIPEED_DETAIL_COLR, borderRadius:5}}>           
                                    <Row className="justify-content-xm-center">
                                        <Col md={6}>
                                            <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                                <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].batchStartDateKey}</p>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                            <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].batchStartDate}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{padding:5, marginTop: 2, textAlign:"center", backgroundColor: STRIPEED_DETAIL_COLR, borderRadius:5}}>           
                                    <Row className="justify-content-xm-center">
                                        <Col md={6}>
                                            <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                                <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].batchNameKey}</p>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                            <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].batchName}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{padding:5, marginTop: 2, textAlign:"center", backgroundColor: STRIPEED_DETAIL_COLR, borderRadius:5}}>           
                                    <Row className="justify-content-xm-center">
                                        <Col md={6}>
                                            <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                                <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].batchStartDateKey}</p>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                            <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].batchStartDate}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card.Body>
                        <Card.Footer>
                            <Row className="justify-content-xm-center">
                                <Col md={6}>
                                    <div style={{marginTop:10, textAlign:"center", backgroundColor: rgb(255*Math.random(),255*Math.random(),255*Math.random() ), borderRadius:5, border: "1px solid red"}}>
                                        <p style={{fontSize: 10,margin:0,padding:0, color:"white"}}>Performance</p>
                                    </div>
                                    
                                </Col>
                                <Col md={6}>
                                    <div style={{marginTop:10, textAlign:"center", backgroundColor: rgb(255*Math.random(),255*Math.random(),255*Math.random() ), borderRadius:5, border: "1px solid red"}} onClick={() => this.toggleDetailField(i, j)}>
                                        <p style={{fontSize: 10,margin:0,padding:0, color:"white"}}>Exam And Test</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-xm-center">
                                <Col md={6}>
                                    <div style={{marginTop:10, textAlign:"center", backgroundColor: rgb(255*Math.random(),255*Math.random(),255*Math.random() ), borderRadius:5, border: "1px solid red"}}>
                                        <p style={{fontSize: 10,margin:0,padding:0, color:"white"}}>TimeTable</p>
                                    </div>
                                    
                                </Col>
                            </Row>
                            <Row className="justify-content-xm-center">
                                <Col md={6}>
                                    <div style={{marginTop:10, textAlign:"center", backgroundColor: rgb(255*Math.random(),255*Math.random(),255*Math.random() ), borderRadius:5, border: "1px solid red"}}>
                                        <ProgressBar animated now={Math.random()*100} />
                                    </div>
                                    
                                </Col>
                                <Col md={6}>
                                    <div style={{marginTop:10, textAlign:"center", backgroundColor: rgb(255*Math.random(),255*Math.random(),255*Math.random() ), borderRadius:5, border: "1px solid red"}} onClick={() => this.toggleDetailField(i, j)}>
                                        <p style={{fontSize: 10,margin:0,padding:0, color:"white"}}>Certificate</p>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                </Col>
                );
            }

            let finalRow = <Row className="justify-content-xm-center" style={{marginBottom:10}}>{column}</Row>;
            rows.push(finalRow);
        }

        //handling the last row
        let lastRowColumn = []
        let i = numOfColInLastRow-1;
        for(let j = 0; j < numOfColInLastRow; j++){
            lastRowColumn.push(
                <Col xs={BOOT_COL_SPACE}>
                <Card style={{borderWidth:1, borderColor:"black", borderStyle:"dotted"}}>

                    <img style={{display: 'block', width: window.innerWidth, height: 150}}
                             className="d-block w-100"
                             src={DATA[i*NUM_OF_COL+j].imageUrl}
                             alt="Image One"
                    />
                    <Card.Body>
                        <div id="Course-Batch">
                            <div style={{padding:5, marginTop: 2, textAlign:"center", backgroundColor: STRIPEED_DETAIL_COLR, borderRadius:5}}>           
                                <Row className="justify-content-xm-center">
                                    <Col md={6}>
                                        <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                            <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].courseIDKey}</p>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                        <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].courseID}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{padding:5, marginTop: 2, textAlign:"center", backgroundColor: STRIPEED_DETAIL_COLR, borderRadius:5}}>           
                                <Row className="justify-content-xm-center">
                                    <Col md={6}>
                                        <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                            <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].CourseNameKey}</p>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                        <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].courseName}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{padding:5, marginTop: 2, textAlign:"center", backgroundColor: STRIPEED_DETAIL_COLR, borderRadius:5}}>           
                                <Row className="justify-content-xm-center">
                                    <Col md={6}>
                                        <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                            <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].courseRegDateKey}</p>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                        <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].courseRegDate}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{padding:5, marginTop: 2, textAlign:"center", backgroundColor: STRIPEED_DETAIL_COLR, borderRadius:5}}>           
                                <Row className="justify-content-xm-center">
                                    <Col md={6}>
                                        <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                            <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].batchStartDateKey}</p>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                        <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].batchStartDate}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{padding:5, marginTop: 2, textAlign:"center", backgroundColor: STRIPEED_DETAIL_COLR, borderRadius:5}}>           
                                <Row className="justify-content-xm-center">
                                    <Col md={6}>
                                        <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                            <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].batchNameKey}</p>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                        <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].batchName}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{padding:5, marginTop: 2, textAlign:"center", backgroundColor: STRIPEED_DETAIL_COLR, borderRadius:5}}>           
                                <Row className="justify-content-xm-center">
                                    <Col md={6}>
                                        <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                            <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].batchStartDateKey}</p>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div style={{marginTop:2, textAlign:"center", backgroundColor:'white', borderRadius:5}}>
                                        <p style={{fontSize: 10,margin:0,padding:0}}>{DATA[i*NUM_OF_COL+j].batchStartDate}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                    <Row className="justify-content-xm-center">
                                <Col md={6}>
                                    <div style={{marginTop:10, textAlign:"center", backgroundColor: rgb(255*Math.random(),255*Math.random(),255*Math.random() ), borderRadius:5, border: "1px solid red"}}>
                                        <p style={{fontSize: 10,margin:0,padding:0, color:"white"}}>Performance</p>
                                    </div>
                                    
                                </Col>
                                <Col md={6}>
                                    <div style={{marginTop:10, textAlign:"center", backgroundColor: rgb(255*Math.random(),255*Math.random(),255*Math.random() ), borderRadius:5, border: "1px solid red"}} onClick={() => this.toggleDetailField(i, j)}>
                                        <p style={{fontSize: 10,margin:0,padding:0, color:"white"}}>Exam And Test</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-xm-center">
                                <Col md={6}>
                                    <div style={{marginTop:10, textAlign:"center", backgroundColor: rgb(255*Math.random(),255*Math.random(),255*Math.random() ), borderRadius:5, border: "1px solid red"}}>
                                        <p style={{fontSize: 10,margin:0,padding:0, color:"white"}}>TimeTable</p>
                                    </div>
                                    
                                </Col>
                            </Row>
                            <Row className="justify-content-xm-center">
                                <Col md={6}>
                                    <div style={{marginTop:10, textAlign:"center", backgroundColor: rgb(255*Math.random(),255*Math.random(),255*Math.random() ), borderRadius:5, border: "1px solid red"}}>
                                        <ProgressBar animated now={Math.random()*100} />
                                    </div>
                                    
                                </Col>
                                <Col md={6}>
                                    <div style={{marginTop:10, textAlign:"center", backgroundColor: rgb(255*Math.random(),255*Math.random(),255*Math.random() ), borderRadius:5, border: "1px solid red"}} onClick={() => this.toggleDetailField(i, j)}>
                                        <p style={{fontSize: 10,margin:0,padding:0, color:"white"}}>Certificate</p>
                                    </div>
                                </Col>
                            </Row>
                    </Card.Footer>
                </Card>
            </Col>
            );
        }

        let lastRow = <Row className="justify-content-xm-center" style={{marginBottom:10}}>{lastRowColumn}</Row>;
        rows.push(lastRow);
        return rows;



    }

    render() {
        return (
            <div id={"main-contain"} style={{ height: this.getHeight(), width: "100%",marginTop:2,
                marginBottom:2, paddingBottom:10, marginLeft:0,borderRadius:0, boxShadow: "0px 0px 8px black"}}>
                <div style={{float:"right", marginTop:10, marginRight:10}}>
                </div>
                <div style={{clear:"both"}}></div>

                <div id="F4EScholarships">
                
                    <Row style={{marginTop:20}}>
                        <Col>
                            <div id="user-personal-detail" style={{ width: 250, height:50, marginLeft: 20, padding:5, boxShadow: "2px 2px 5px black",backgroundColor:"white", borderRadius:5, backgroundImage:`url(${ClassRoom})` }}>
                                <h3 style={{float:"left", marginLeft:10, marginTop:3}}>Course enrolled</h3>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{marginTop:20}}>
                        <Col>
                            <div id="user-personal-detail" style={{marginLeft: 20,marginRight: 10, padding:15, boxShadow: "2px 2px 5px black",backgroundColor:"white", borderRadius:5 }}>
                            <Row>
                                    <Col>
                                        <div id="Course-Detail">
                                            <div>
                                                {this.coursesDetailInGrid()}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                   
                {/* **** */}

                </div>
            </div>
        )
    }
}

export default Detail;
