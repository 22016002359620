import  React,  {  Component  }  from  'react';
import {Form, Button,OverlayTrigger,Tooltip} from "react-bootstrap";
import  "./FooterStyleSheet.css";
import {instanceOf} from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import udemy from './../../../img/udemy/udemy.png';
import facebook from './../../../img/facebook/facebook.png';
import youtube from './../../../img/youtube/youtube.png';
import electrons from './../../../img/animation/electrons.gif';
import varanasi from './../../../img/address/Varanasi.png';
import banglore from './../../../img/address/Banglore.png';
import ceo from './../../../img/ceo/nagina.png';

class Footer extends  Component{


    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    entering = (e) => {
        e.children[1].style.borderTopColor = 'green';
        e.children[1].style.backgroundColor = 'green';
    };

    tooltip = (
        <Tooltip id="tooltip" style={{margin:20}}>This is the tooltip. Yeah!his is the tooltip. Yeah!his is the tooltip. 
        Yeah!his is the tooltip. Yeah!his is the tooltip. Yeah!
        his is the tooltip. Yeah!his is the tooltip. Yeah!his is the tooltip. Yeah!</Tooltip>
    );

    render() {
        return (
            
            <div>
                <div style={{ height: 200, width: '100%', alignContent: "center", backgroundColor: "#1F384D", }}>
                    <div style={{ display: 'flex' , marginLeft:20}}>
                        <div>
                            <img style={{ width: 170, height:200, width: 170, marginLeft:30, marginTop: 0 }} src={ceo} alt="Image One" />
                        </div>
                        <div style={{ marginLeft: 40 }}>
                            <div style={{height: 120}}>
                            </div>

                            <div style={{color: 'white'}}>
                                <br></br>
                                Nagina Patel
                                <br></br>
                                <h4>Director</h4>
                              
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ height: 300, width: '100%', alignContent: "center", backgroundColor: 'black', }}>
                    <div style={{height:150, backgroundColor: 'black'}}>
                    </div>

                    <div style={{   height: 200, backgroundColor: 'black'}}>
                         <div style={{float:'left', width: '35%', height:"100%"}}>
                            <div style={{height: 70, width:"100%"}}>

                            </div>
                            <div >
                                <div style={{float: "left", height:"100%", width:"30%"}} >
                                    <img style={{  width: "50%", height:90}}  src={facebook} alt="Image One" />
                                </div>
                                <div style={{float: "left", height:"100%", width:"30%"}} >
                                    <img style={{  width: "50%", height:90 }} src={youtube} alt="Image One" />
                                </div>
                                <div style={{float: "left", height:"100%", width:"30%"}} >
                                    <img style={{ width: "50%", height:90 }}  src={udemy} alt="Image One" />
                                </div>
                            </div>
                        </div>
                        
                        <div style={{float:'left', width: '30%', height:"100%"}}>
                            <div style={{float: "left", height:"100%", width:"30%"}} >
                               
                            </div>
                            <div style={{float: "left", height:"100%", width:"30%"}} >
                                <img style={{ height:"100%", width:"150%" }}  src={electrons} alt="Image One" />
                            </div>
                            <div style={{float: "left", height:"100%", width:"30%"}} >
                               
                            </div>
                        </div>

                        <div style={{float:'right', width: '35%', height:"100%"}}>
                            <div style={{float: "left", height:"100%", width:"50%"}} >
                               
                            </div>
                            <div style={{float: "left", height:"100%", width:"50%"}} >
                                <img style={{ height:"60%", width:"100%", marginRight:"100px", marginTop:"50px" }}  src={varanasi} alt="Image One" />
                            </div>
                           
                        </div>
                    </div>
                </div>

                <div style={{ height: 100, width: '100%', alignContent: "center", backgroundColor: 'black' }}>
                    <div style={{float: "right"}}>
                        <OverlayTrigger overlay={ <Tooltip id="tooltip-disabled">Tooltip!</Tooltip>}>
                            <span>
                                <Button variant="outline-primary" size="sm" style={{marginLeft:10,marginTop:10, height:20}}>
                                    <p style={{fontSize:9}}>Site-Map</p>
                                </Button>
                            </span>
                        </OverlayTrigger>
                    </div>
                    <div style={{float: "right"}}>
                        <OverlayTrigger overlay={ <Tooltip id="tooltip-disabled">Tooltip!</Tooltip>}>
                            <span>
                                <Button variant="outline-primary" size="sm" style={{marginLeft:10,marginTop:10, height:20}}>
                                    <p style={{fontSize:9}}>Contact</p>
                                </Button>
                            </span>
                        </OverlayTrigger>
                    </div>
                    <div style={{float: "right"}}>
                        <OverlayTrigger overlay={ <Tooltip id="tooltip-disabled">Tooltip!</Tooltip>}>
                            <span>
                                <Button variant="outline-primary" size="sm" style={{marginLeft:10,marginTop:10, height:20}}>
                                    <p style={{fontSize:9}}>Privacy</p>
                                </Button>
                            </span>
                        </OverlayTrigger>
                    </div>
                    <div style={{float: "right"}}>
                        <OverlayTrigger overlay={ <Tooltip id="tooltip-disabled">Tooltip!</Tooltip>}>
                            <span>
                                <Button variant="outline-primary" size="sm" style={{marginLeft:10,marginTop:10, height:20}}>
                                    <p style={{fontSize:9}}>Term Of Use</p>
                                </Button>
                            </span>
                        </OverlayTrigger>
                    </div>
                    <div style={{float: "right"}}>
                        <OverlayTrigger overlay={ <Tooltip id="tooltip-disabled">Tooltip!</Tooltip>}>
                            <span>
                                <Button variant="outline-primary" size="sm" style={{marginLeft:10,marginTop:10, height:20}}>
                                    <p style={{fontSize:9}}>Legal</p>
                                </Button>
                            </span>
                        </OverlayTrigger>
                    </div>
                    <div style={{float: "right"}}>
                        <OverlayTrigger overlay={ 
                            <Tooltip id="tooltip-disabled">
                                <div style={{textAlign: "left-justify"}}>
                                    CopyrightUser.org is an independent online resource aimed at making UK Copyright Law accessible to creators, media professionals, entrepreneurs, students, and members of the public.
                                </div>
                            </Tooltip>
                            }>
                            <span>
                                <Button variant="outline-primary" size="sm" style={{marginLeft:10,marginTop:10, height:20}}>
                                    <p style={{fontSize:9}}>Copyright © 2021</p>
                                </Button>
                            </span>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>
                 
        )
    }
}

export default withCookies(Footer);