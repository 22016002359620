export const SIXTH = "VI";
export const SEVENTH = "VII";
export const EIGHTH = "VIII";
export const NINTH = "IX";
export const TENTH = "X";
export const ELEVENTH = "XI";
export const TWELFTH = "XII";

export const GOVT_JOBS = "GOVT JOBS";
export const DIPLOMA = "DIPLOMA";
export const ITI = "ITI";
export const B_TECH = "B_TECH";
export const BSC = "BSC";
export const MSC = "MSC";

export const CURRENT_AFFAIRS = "CURRENT_AFFAIRS";

export const ALL_STD = "ALL_STD";
