import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import {Fight4EducObj} from '../../constants/Fight4EduObjective';
import axios from "axios";
import * as ApiUrl from "../../api-url/ApiUrl";
import {FcSettings,FcRating,FcAssistant,FcOvertime,FcBinoculars,
    FcPieChart,FcNews,FcViewDetails,FcMindMap,FcCdLogo,FcHome,
    FcIdea,FcDocument,FcPhone} from "react-icons/fc";

let DATA =[{
    "objective":"Fight For Education provide instruction and teaching in some subjects of engineering and science,current-affairs and arts for the advancement of learning and dissemination of knowledge in specific branches.We also provide lectures and online-tuition/coaching for the students for get the jobs, enter into IIT, DRO/ISRO.To raise their level of comprehension of basic subjects to provide a stronger foundation for further academic workalong with we improve the academic skills and linguistic proficiency of the students in various subjects.Our Mission is to filter out the best from every student and focus on providing the best possible educational."
}];

class F4EObjective extends  Component{

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }


    componentDidMount() {
        axios.get(ApiUrl.GET_OBJECTIVE).then(response=> {
            if (response.status === 200) {
                DATA = response.data.data;
                this.setState({ isLoading: false });
            }else { throw new Error("Bad response from server"); }
        }).catch((err)=>{
            this.setState({signupInfo: {errorStatus:true, msg:err.response.data.message}});
            setTimeout(() => {
                this.setState({signupInfo: {errorStatus:false, msg:err.response.data.message}});
            }, 4000)
        });

    }


    render() {

        const { isLoading } = this.state;

        {
            /*
        if (isLoading) {
            console.log("::::loading..... -->");
            return <div className="Slider">Loading...</div>;
        }
        */
        }

        return (
            <div style={{ margin:0, padding:0,height: window.innerHeight*0.6, background: `url("https://ik.imagekit.io/fight4edu/common/objective/galaxy-solar-system.gif")`,backgroundRepeat: "no-repeat", backgroundSize: "100%" }}>
                <div style={{width:400, marginLeft:20 }}>
                    {/* First Element is Logo */}
                    <div style={{alignContent:"center",color:'yellow'}}>
                        <FcCdLogo style={{ height:30,width:30}}/>
                        <p style={{fontSize: 14,marginBottom:3,padding:0}}>About Fight For Education</p>
                    </div>
                    <div style={{textAlign:"justify",fontSize: 10,fontStyle:"italic" , color:'#ffffcc', marginTop:0, padding:3,borderRadius:5, border:'0px dotted black'}}>
                        {DATA[0].objective}
                    </div>  
                </div>
            </div>
        )
    }
}

export default F4EObjective;

