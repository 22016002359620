import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {FcSettings,FcRating,FcAssistant,FcOvertime,FcBinoculars,
    FcPieChart,FcNews,FcViewDetails,FcMindMap,FcCdLogo,FcHome,
    FcIdea,FcDocument,FcPhone} from "react-icons/fc";


let DATA =[{
    "objective":"Fight For Education provide instruction and teaching in some subjects of engineering and science,current-affairs and arts for the advancement of learning and dissemination of knowledge in specific branches.We also provide lectures and online-tuition/coaching for the students for get the jobs, enter into IIT, DRO/ISRO.To raise their level of comprehension of basic subjects to provide a stronger foundation for further academic workalong with we improve the academic skills and linguistic proficiency of the students in various subjects.Our Mission is to filter out the best from every student and focus on providing the best possible educational."
}];

class Help extends  Component{

    render() {
        return (
            <div id={"main-contain"} style={{width: "100%",height: window.innerHeight*0.16,background: `url("https://ik.imagekit.io/fight4edu/common/objective/galaxy-solar-system.gif")`,backgroundRepeat: "no-repeat", backgroundSize: "100%" }}>
                <div style={{width: "100%"}}>
                    {/* First Element is Logo */}
                    <div style={{alignContent:"center",color:'yellow'}}>
                        <FcCdLogo style={{ height:30,width:30}}/>
                        <p style={{fontSize: 14,marginBottom:3,padding:0}}>About Fight For Education</p>
                    </div>
                    <div style={{textAlign:"justify",fontSize: 10,fontStyle:"italic" , color:'#ffffcc', marginTop:0, padding:3,borderRadius:5, border:'0px dotted black'}}>
                        {DATA[0].objective}
                    </div>  
                </div>
            
            </div>
        )
    }
}

export default Help;

