import {Component} from 'react';
import Home from "./components/home/Home";

class DeskApp extends  Component{

    render() {
        return (
            <div>
                <Home></Home>
            </div>

        )
    }
}

export default DeskApp;
