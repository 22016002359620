export const BUY_BOOK = "BUY_BOOK";
export const CENTRAL_CONTENT = "CENTRAL_CONTENT";
export const LOGIN_INFO = "LOGIN_INFO";
export const CERTIFICATE = "CERTIFICATE";
export const EXAM_AND_TEST = "EXAM_AND_TEST";
export const HELP = "HELP";
export const SEARCH_AND_REG = "SEARCH_AND_REG";
export const SETTING = "SETTING";
export const USER_DETAIL = "USER_DETAIL";
export const WALLET = "WALLET";
export const STD_CLASS = "STD_CLASS";