export const CLASSES_TIME_TABLE = "CLASSES_TIME_TABLE";
export const ADMISSION_NOTICE = "ADMISSION_NOTICE";
export const RESULT_AWARD = "RESULT_AWARD";
export const GENIUS_OF_MONTH = "GENIUS_OF_MONTH";
export const CENTRAL_CONTENT = "CENTRAL_CONTENT";
export const EXCELLENT_PERFORMER = "EXCELLENT_PERFORMER";
export const F4E_SCHOLARSHIPS = "F4E_SCHOLARSHIPS";
export const F4E_COURSES = "F4E_COURSES";
export const LOGIN_INFO = "LOGIN_INFO";
export const USER_INFO = "USER_INFO";
export const EXAM_AND_TEST = "EXAM_AND_TEST";
export const CERTIFICATE = "CERTIFICATE";
export const HELP = "HELP";
export const SETTING = "SETTING";
export const WALLET = "WALLET";
export const TIME_TABLE = "TIME_TABLE";
export const SEARCH = "SEARCH";
export const DETAIL = "DETAIL";
export const GRID_MAP = "GRID_MAP";
export const ABOUT_F4E = "ABOUT_F4E";
export const CONTACT = "CONTACT";
export const SUCCESS = "SUCCESS";
export const SCHOLARSHIP_FORM = "SCHOLARSHIP_FORM";
export const EDUCATION_STRUCT = "EDUCATION_STRUCT";
export const STD_CLASS ="STD_CLASS";
export const COURSE_DETAILS = "COURSE_DETAILS";
